import axios, { AxiosInstance } from 'axios'
import { getAccessToken } from 'utils/authHelpers'

export class ApiClinetWithMasterToken {
  axiosInstance: AxiosInstance

  constructor(baseUrl: string) {
    this.axiosInstance = axios.create({
      baseURL: baseUrl,
      timeout: 90000,
    })

    this.axiosInstance.interceptors.request.use((config) => {
      if (getAccessToken() != null) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IkY4TDRhM0MyVmd1NW9uOG55ektPUG1CRjFxcDdFbFAtZjJLQ280NkdISG8iLCJ0eXAiOiJKV1QifQ.eyJzY29wZSI6Im9wZW5pZCBmaGlyVXNlciBlbWFpbCByb2xlcyBvZmZsaW5lX2FjY2VzcyIsImlzcyI6Imh0dHBzOi8vYWNjb3VudC53ZWxsb3BhdGh5LmNvbSIsImF1ZCI6InBsYXRmb3JtIiwiX19jbGllbnRfaWQiOiJzZXJ2aWNlL3dzZSIsImV4cCI6MTc1ODcwNzY3MiwiaWF0IjoxNzI3MTcxNjcyLCJuYmYiOjE3MjcxNzE2NzJ9.jSmU6L5QH0-jYMxSM9W3wTIKrMiXJKtBXF9cEValNCl7tTsTGqiwv5CKEHNkvgrU4ZV6p2aE0SeR1VbMNDqg5TGXX-3ULOW3EmPkPocRTrTX1ihYlq18Tc9uXR2wtlQBsL3Y6VJdPQNwsgula4LTwPhcar5LxS99Py3uLG1ZTZ1tM2HX7QMmGWKfoA8J2gefSka5CWutOTimb-u4dcwiRjwzlwgmCitZgxwpy6Nvi8rN9ZNgUMXQ0aTh9PQKg8FY8-ecLxxCDRyig5qOJlzs4DGsPfqynm9hvS0NIP3zrKfS0JWR4eNN5rnKyQE85Zkl5QhzTH9PrKLXgz7d5BZYBw`,
          'Cache-Control': 'no-cache',
        }
      }

      return config
    })

    this.axiosInstance.interceptors.response.use((param) => ({
      ...param,
    }))
  }
}
