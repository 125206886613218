import { Box } from '@material-ui/core'
import _ from 'lodash'
import { CarePlan } from 'models/carePlan'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import { resetDietSearchState } from 'redux/consultation/dietPlan/existingDietPlanSearch/existingDietPlanSearchSlice'
import { resetAppointmentState } from 'redux/appointments/appointmentManger/appointmentManagerSlice'
import { resetSearchAppointmentForDisplay } from 'redux/appointments/appointmentViewer/appointmentSearchSlice'
import { resetFollowupAppointmentState } from 'redux/appointments/followupManager/followAappointmentManagerSlice'

import { getImagingRequestsOfAppointment } from 'redux/consultation/imaging_diagnostics_requests_list/imagingDiagnosticsListSlice'
import { getInstructionsOfAppointment } from 'redux/consultation/instructions_list_slice/instructionsListSlice'
import { getLabRequestsOfAppointment } from 'redux/consultation/lab_diagnostics_requests_list/labDiagnosticsListSlice'
import { getWellnessServiceRequestsOfAppointment } from 'redux/consultation/wellness_referrals_list/wellnessReferralsListSlice'
import { resetSlotSelectionStatus } from 'redux/fhirMedicalResources/clinicalImpression/clinicalImpressionSlice'
import { fetchFollowUpAppointments } from 'redux/fhirMedicalResources/followUpAppointmentFetcher/followUpAppointmentSearchSlice'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { RootState } from 'redux/rootReducer'
import { isAyurvedaDoctor } from 'services/userDetailsService'
import { getNameFromHumanName } from 'utils/fhirResourcesHelper'
import { CreateAppointmentHandler } from 'views/components/appointments/createAppointmentManager'
import { isTermsAndConditionAccepted } from 'utils/careplan_utils/subscription_helpers/subscriptionHelper'
import { AddWellnessReferrals } from '../assessment/addWellnessReferrals'
import { AddCustomInstructions } from './addCustomInstruction'
import { AddImagingDiagnosticsTests } from './addImagingDiagnosticOrders'
import { AddDiagnosticsTests } from './add_diagnostic_orders'
import { AddKriyasForEncounters } from './ayurveda/addKriyasForEncounter'
import {
  AddPathyaApathya,
  AddPathyaApathyaProps,
} from './ayurveda/addPathyaAndApathya'
import { PathyaApathyaListForEncounter } from './ayurveda/pathyaApathyaList'
import { ImagingDiagnosticsList } from './imagingDiagnosticsList'
import { CarePlanInstructionList } from './instructionsList'
import { LabDiagnosticRequestsList } from './labDiagnosticList'
import { NextStepPlan } from './nextStepPlan'
import { NextStepMultipleSubPlan } from './nextStepPlanMultipleSubPlan'
import { PatientFolloWUpAppointments } from './pateintFollowUpAppointments'
import { SpecialistReferralList } from './specialistRequestList'
import { WellnessReferralsList } from './wellnessRefferalsList'

interface NextStepsOfConsultationProps {
  fhirAppointmentDetails: FhirAppointmentDetail
  split: boolean
}

export const HalfWidthNextStepsOfConsultation: React.FC<NextStepsOfConsultationProps> =
  ({ fhirAppointmentDetails, split }: NextStepsOfConsultationProps) => {
    const { t } = useTranslation(['common', 'mediction'])
    const [addButton, showAddButton] = useState<boolean>(false)
    const [show, setShow] = useState<boolean>(false)
    const [showCohorts, setShowCohorts] = useState<boolean>(false)
    const plans: CarePlan[] = [
      {
        planName: 'Hypertension Care Plan',
        planDescription: '',
      },
    ]

    const patientSubscriptionForUnitSlice = useSelector(
      (state: RootState) => state.patientSubscriptionForUnitSlice
    )

    const [openAddMedicationDialogue, setOpenAddMedicationDialogue] =
      useState<boolean>(false)
    const [openAddWellReferrals, setOpenAddWellReferrals] =
      useState<boolean>(false)
    const [openDietPlan, setOpenDietPlan] = useState<boolean>(false)
    const [openKriyas, setOpenKriyas] = useState<boolean>(false)
    const [openAddInstruction, setAddInstruction] = useState<boolean>(false)
    const [openAddDiagnosticDetails, setOpenAddDiagnosticDetails] =
      useState<boolean>(false)
    const [
      openAddImagingDiagnosticDetails,
      setOpenAddImagingDiagnosticDetails,
    ] = useState<boolean>(false)
    const dispatch = useDispatch()
    const [openCreateAppointmentPopup, setOpenCreateAppointmentPopup] =
      useState(false)

    const [addPathyaApathyProps, setAddPathyaApathyProps] =
      useState<AddPathyaApathyaProps>({
        encounterReference: {
          reference: `Encounter/${fhirAppointmentDetails.encounter!.id}`,
        },
        patientReference: {
          reference: `Patient/${fhirAppointmentDetails.patient.id}`,
        },
        onClose: () => {},
        onLabDiagnosticsAdded: () => {},
        open: false,
      })

    const [aharaUpdateState, setAharaUpdateState] = useState<string>(
      _.random(0, 10000).toString()
    )
    const [viharaUpdateState, setViharaUpdateState] = useState<string>(
      _.random(0, 10000).toString()
    )
    const [kriyaListState, setKriyaListState] = useState<string>(
      _.random(0, 10000).toString()
    )

    useEffect(() => {
      dispatch(resetDietSearchState())
    }, [dispatch])

    return (
      <ReactVisibilitySensor
        offset={{
          top: 100,
          bottom: 30,
        }}
        onChange={(isVisible) => {
          if (isVisible) {
            dispatch(setSelectedSection({ selectedSection: 'next_steps' }))
          }
        }}
      >
        <Box display='flex' flexDirection='column' width='100%'>
          <Box display='flex' flexDirection='row' padding={0.5} width='100%'>
            <NextStepPlan
              planName={t('labelCommon:lab_test')}
              subString=''
              bottomWidget={
                <LabDiagnosticRequestsList
                  patient={fhirAppointmentDetails.patient}
                  practitionerDetails={
                    fhirAppointmentDetails.practitionerDetail
                  }
                  encounter={fhirAppointmentDetails.encounter}
                  followUp={false}
                  supportingInfo={
                    fhirAppointmentDetails.appointment.supportingInformation ??
                    []
                  }
                  appointmentId={fhirAppointmentDetails.appointment.id!}
                />
              }
              onClicked={() => {
                setOpenAddDiagnosticDetails(true)
              }}
            />
          </Box>

          <Box display='flex' flexDirection='row' padding={0.5} width='100%'>
            <NextStepPlan
              planName='Imaging Studies'
              subString=''
              bottomWidget={
                <ImagingDiagnosticsList
                  fhirAppointmentDetails={fhirAppointmentDetails}
                  split={split}
                />
              }
              onClicked={() => {
                setOpenAddImagingDiagnosticDetails(true)
              }}
            />
          </Box>
          <Box display='flex' flexDirection='row' padding={0.5}>
            <NextStepPlan
              planName='Follow-up Appointments'
              subString=''
              bottomWidget={
                <PatientFolloWUpAppointments
                  fhirPatientDetail={fhirAppointmentDetails}
                />
              }
              onClicked={() => {
                setOpenCreateAppointmentPopup(true)
              }}
            />
          </Box>
          {/*  <Box display='flex' flexDirection='row' padding={0.5}>
              <NextStepPlan
                planName='Referral Appointment'
                subString=''
                onClicked={() => {}}
                bottomWidget={
                  <ReferralRequestList
                    fhirAppointmentDetails={fhirAppointmentDetails}
                  />
                }
              />
            </Box> */}
          <Box display='flex' flexDirection='row' padding={0.5}>
            <NextStepPlan
              planName='Wellness Referrals'
              subString=''
              onClicked={() => {
                if (
                  patientSubscriptionForUnitSlice.subscriptionStatus ===
                  'not-offered'
                ) {
                  setOpenAddWellReferrals(true)
                } else if (
                  patientSubscriptionForUnitSlice.subscriptionStatus !==
                  'active'
                ) {
                  const { task } = patientSubscriptionForUnitSlice.tasks![0]
                  if (isTermsAndConditionAccepted(task) === true) {
                    dispatch(
                      showErrorAlert(
                        'Patient subscription is not active. Please activate the subscription to refer wellness services.'
                      )
                    )
                  } else {
                    dispatch(
                      showErrorAlert(
                        'Patient subscription is not active. Please ask patient to  accept the terms and conditions in App, to refer wellness services.',
                        undefined,
                        'Subscription not active',
                        true
                      )
                    )
                  }
                } else {
                  setOpenAddWellReferrals(true)
                }
              }}
              bottomWidget={
                <WellnessReferralsList
                  fhirAppointmentDetails={fhirAppointmentDetails}
                />
              }
            />
          </Box>

          <Box display='flex' flexDirection='row' padding={0.5} width='100%'>
            <NextStepPlan
              planName='Instructions'
              subString=''
              bottomWidget={
                <CarePlanInstructionList
                  fhirAppointmentDetails={fhirAppointmentDetails}
                  split={true}
                />
              }
              onClicked={() => {
                if (
                  fhirAppointmentDetails.encounter !== undefined &&
                  fhirAppointmentDetails.encounter.id !== undefined
                ) {
                  setAddInstruction(true)
                } else {
                  dispatch(
                    showErrorAlert(
                      'No details have been added as part of Appointment. Please add few details about patient visit'
                    )
                  )
                }
              }}
            />
          </Box>

          <Box display='flex' flexDirection='row' padding={0.5} width='100%'>
            <NextStepPlan
              planName='Specialist Referral Note'
              subString=''
              bottomWidget={<SpecialistReferralList />}
            />
          </Box>

          {isAyurvedaDoctor() && (
            <Box display='flex' flexDirection='row' padding={0.5} width='100%'>
              <NextStepMultipleSubPlan
                planName='Pathya Apathya'
                subPlans={[
                  {
                    subPlanName: 'Ahara',
                    subString: '',
                    isAddDisplay: !split,
                    isEditAllowed: !split,
                    bottomWidget: (
                      <PathyaApathyaListForEncounter
                        fhirAppointmentDetails={fhirAppointmentDetails}
                        pathyaApathyaTypeCode={{
                          system: 'http://www.who.int/ita',
                          code: 'ITA-8.1.2',
                          display: 'Ahara',
                        }}
                        updateList={aharaUpdateState}
                        isReadonly={split}
                      />
                    ),
                    onClicked: () => {
                      setAddPathyaApathyProps({
                        ...addPathyaApathyProps,
                        open: true,
                        pathyaApathyaTypeCode: {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-8.1.2',
                          display: 'Ahara',
                        },

                        onLabDiagnosticsAdded: () => {
                          setAharaUpdateState(_.random(0, 1000).toString())
                        },
                        onClose: () => {
                          setAddPathyaApathyProps({
                            ...addPathyaApathyProps,
                            open: false,
                          })
                        },
                      })
                    },
                  },
                  {
                    subPlanName: 'Vihara',
                    isAddDisplay: true,
                    isEditAllowed: true,
                    bottomWidget: (
                      <PathyaApathyaListForEncounter
                        fhirAppointmentDetails={fhirAppointmentDetails}
                        pathyaApathyaTypeCode={{
                          system: 'http://www.who.int/ita',
                          code: 'ITA-10.2.14',
                          display: 'Vihara',
                        }}
                        updateList={viharaUpdateState}
                        isReadonly={split}
                      />
                    ),
                    subString: '',
                    onClicked: () => {
                      setAddPathyaApathyProps({
                        ...addPathyaApathyProps,
                        open: true,
                        pathyaApathyaTypeCode: {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-10.2.14',
                          display: 'Vihara',
                        },
                        onLabDiagnosticsAdded: () => {
                          setViharaUpdateState(_.random(0, 1000).toString())
                        },
                        onClose: () => {
                          setAddPathyaApathyProps({
                            ...addPathyaApathyProps,
                            open: false,
                          })
                        },
                      })
                    },
                  },
                ]}
              />
            </Box>
          )}
          <AddDiagnosticsTests
            open={openAddDiagnosticDetails}
            encounter={fhirAppointmentDetails.encounter!}
            patient={fhirAppointmentDetails.patient}
            practitionerDetails={fhirAppointmentDetails.practitionerDetail}
            onClose={() => {
              dispatch(
                getLabRequestsOfAppointment(
                  fhirAppointmentDetails.patient,
                  fhirAppointmentDetails.practitionerDetail,
                  fhirAppointmentDetails.encounter
                )
              )
              setOpenAddDiagnosticDetails(false)
            }}
            onLabDiagnosticsAdded={() => {
              dispatch(
                getLabRequestsOfAppointment(
                  fhirAppointmentDetails.patient,
                  fhirAppointmentDetails.practitionerDetail,
                  fhirAppointmentDetails.encounter
                )
              )
              setOpenAddDiagnosticDetails(false)
            }}
          />

          {openAddImagingDiagnosticDetails && (
            <AddImagingDiagnosticsTests
              open={openAddImagingDiagnosticDetails}
              patientReference={{
                reference: `Patient/${fhirAppointmentDetails.patient.id}`,
              }}
              encounterReference={{
                reference: `Encounter/${fhirAppointmentDetails.encounter!.id}`,
              }}
              onClose={() => {
                dispatch(
                  getImagingRequestsOfAppointment(fhirAppointmentDetails)
                )
                setOpenAddImagingDiagnosticDetails(false)
              }}
              onLabDiagnosticsAdded={() => {
                dispatch(
                  getImagingRequestsOfAppointment(fhirAppointmentDetails)
                )
                setOpenAddImagingDiagnosticDetails(false)
              }}
            />
          )}

          {openKriyas && (
            <AddKriyasForEncounters
              open={openKriyas}
              patientReference={{
                reference: `Patient/${fhirAppointmentDetails.patient.id}`,
              }}
              encounterReference={{
                reference: `Encounter/${fhirAppointmentDetails.encounter!.id}`,
              }}
              onClose={() => {
                setKriyaListState(_.random(0, 1000).toString())
                setOpenKriyas(false)
              }}
              onLabDiagnosticsAdded={() => {
                setKriyaListState(_.random(0, 1000).toString())
                setOpenKriyas(false)
              }}
            />
          )}

          {openAddInstruction && (
            <AddCustomInstructions
              open={openAddInstruction}
              patientReference={{
                reference: `Patient/${fhirAppointmentDetails.patient.id}`,
              }}
              encounterReference={{
                reference: `Encounter/${fhirAppointmentDetails.encounter!.id}`,
              }}
              onClose={() => {
                dispatch(getInstructionsOfAppointment(fhirAppointmentDetails))
                setAddInstruction(false)
              }}
              onInstructionAdded={() => {
                dispatch(getInstructionsOfAppointment(fhirAppointmentDetails))
                setAddInstruction(false)
              }}
            />
          )}

          {openAddWellReferrals && (
            <AddWellnessReferrals
              open={openAddWellReferrals}
              fhirAppointmentDetails={fhirAppointmentDetails}
              onClose={() => {
                dispatch(
                  getWellnessServiceRequestsOfAppointment(
                    fhirAppointmentDetails
                  )
                )
                setOpenAddWellReferrals(false)
              }}
              onReferralsAdded={() => {
                dispatch(
                  getWellnessServiceRequestsOfAppointment(
                    fhirAppointmentDetails
                  )
                )
                setOpenAddWellReferrals(false)
              }}
            />
          )}

          <CreateAppointmentHandler
            open={openCreateAppointmentPopup}
            allowWalkIn={false}
            preSelectedDoctor={{
              id:
                fhirAppointmentDetails.practitionerDetail.practitioner.id ?? '',
              roleObject:
                fhirAppointmentDetails.practitionerDetail.practitionerRole,
              practRoleId:
                fhirAppointmentDetails.practitionerDetail.practitionerRole.id,
              practitionerObject:
                fhirAppointmentDetails.practitionerDetail.practitioner,
              name: getNameFromHumanName(
                fhirAppointmentDetails.practitionerDetail.practitioner.name ??
                  []
              ),
              phone: '',
              fullName: '',
              color: '',
              primaryContact: '',
              status: '',
              enabled: fhirAppointmentDetails.practitionerDetail.practitioner
                .active
                ? fhirAppointmentDetails.practitionerDetail.practitioner.active
                : false,
            }}
            fhirAppointmentDetail={fhirAppointmentDetails}
            onClose={() => {
              setOpenCreateAppointmentPopup(false)
              dispatch(fetchFollowUpAppointments(fhirAppointmentDetails))
            }}
            onAppointmentCreated={(createdAppointment) => {
              setOpenCreateAppointmentPopup(false)
              dispatch(resetAppointmentState())
              dispatch(resetSlotSelectionStatus())
              dispatch(resetSearchAppointmentForDisplay())
              dispatch(fetchFollowUpAppointments(fhirAppointmentDetails))
            }}
            followUp={true}
          />
          {addPathyaApathyProps.open && (
            <AddPathyaApathya {...addPathyaApathyProps} />
          )}
        </Box>
      </ReactVisibilitySensor>
    )
  }
