import { R4 } from '@ahryman40k/ts-fhir-types'
import MomentUtils from '@date-io/moment'
import {
  Box,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core'
import { ArrowDropDownOutlined, ArrowDropUpOutlined } from '@material-ui/icons'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import Divider from '@mui/material/Divider'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import {
  AssociatedComplaintData,
  ChiefComplaintData,
  ChiefComplaintTerminology,
} from 'models/chiefComplaintData'
import { ChiefComplaintsData } from 'models/chiefComplaintsData'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { LocationData } from 'models/location'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import {
  IPDgetChiefComplaintOfAppointment,
  resetChiefComplaintDetails,
} from 'redux/consultation/IpdChiefCompaints/IPDchiefComplaintSlice'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { RootState } from 'redux/rootReducer'
import { getChiefComplaintOfAppointmentCreationDate } from 'redux/umls/condition/conditionSearchSlice'
import { isUnitAdmin } from 'services/userDetailsService'
import {
  getCodeValueForChiefComplaints,
  getStringDays,
  getStringMonth,
  getStringValueExtensionsOfUrl,
  getStringValueExtensionsOfUrlForDurationVal,
  getStringWeeks,
  getStringYear,
  getUnitAsString,
  getUnitAsStringForVal,
  getValueRefValueExtensionsOfUrl,
  titleCase,
} from 'utils/fhirResourcesHelper'
import {
  getNotesFromAllergy,
  getNotesString,
  hasNotesForChiefForNote,
  hasNotesForChiefForPatient,
  hasNotesForChiefForPatientText,
  hasSpaces,
} from 'utils/fhirResoureHelpers/allergyHelpers'
import { getObservationValueCodeableConceptDisplayByComponent } from 'utils/fhirResoureHelpers/observationHelpers'
import { logger } from 'utils/logger'
import OverflowTypography from 'views/components/consultation/plan/ayurveda/overflowTypography'
import { AddChiefComplanitsIPD } from 'views/components/consultation/subjective/addChiefComplaintsIPD'

interface ChiefComplaintProps {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  status: string
  splitview: boolean
}

export const IPDChiefComplaint: React.FC<ChiefComplaintProps> = ({
  fhirAppointmentDetails,
  status,
  splitview,
}: ChiefComplaintProps) => {
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null)

  const [t] = useTranslation(['labelCommon', 'labelCondition'])
  const dispatch = useDispatch()

  const [selectedCondition, setSelectedCondition] = useState<R4.ICoding>()
  const [chiefDataArrayData, setChiefDataArrayData] = useState<
    ChiefComplaintsData[]
  >([])

  const [assiChiefDataArrayData, setAssiChiefDataArrayData] = useState<
    ChiefComplaintsData[]
  >([])

  const theme = useTheme()
  const [selectedSeverity, setSeverity] = useState<R4.ICoding>()
  const [selectedLocation, setLocations] = useState<R4.ICoding[]>([])
  const IPDchiefComplaintSlice = useSelector(
    (state: RootState) => state.IPDchiefComplaintSlice
  )
  const [duration, setDuration] = React.useState<string>('')
  const [character, setCharacter] = React.useState<string>('')
  const [alleviating, setAlleviating] = React.useState<string>('')
  const [radiation, setRadiation] = React.useState<string>('')
  const [temporal, setTemporal] = React.useState<string>('')
  const [lastUpdated, setLastUpdated] = React.useState<string>('')
  const [notes, setNotes] = React.useState<R4.IAnnotation[]>()
  const [isActive, setIsActive] = useState<boolean>(false)
  const [addChiefComplaints, setAddChiefComplaints] = useState<boolean>(false)
  const [editChiefComplaints, setEditChiefComplaints] = useState<boolean>(false)
  const [chiefComplaintsDetails, setChiefComplaintsDetails] =
    React.useState<ChiefComplaintsData>()

  const [loading, setLoading] = useState<boolean>(false)
  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })

  const [updatedDateCondition, setUpdatedCondition] = useState<R4.ICondition>()

  function getUpdatedChiefComplaints(condition: R4.ICondition) {
    setUpdateStatus({ requesting: true })

    setLoading(true)

    getChiefComplaintOfAppointmentCreationDate(condition.id!)
      .then((e: R4.ICondition | undefined) => {
        setLoading(false)
        if (e) {
          setUpdatedCondition(e)
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })

          // dispatch(showSuccessAlert('Medication deleted successfully'))
          // dispatch(getMedicationsOfIPD(fhirAppointmentDetails))
        } else {
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          setLoading(false)
        }
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
        setUpdateStatus({
          requesting: false,
          requestSuccess: false,
        })
      })
  }

  useEffect(() => {
    dispatch(
      IPDgetChiefComplaintOfAppointment(fhirAppointmentDetails, splitview)
    )
  }, [dispatch, fhirAppointmentDetails])
  useEffect(() => {
    if (IPDchiefComplaintSlice.updatedConditions) {
      dispatch(resetChiefComplaintDetails)
      dispatch(
        IPDgetChiefComplaintOfAppointment(fhirAppointmentDetails, splitview)
      )
    }
  }, [dispatch, IPDchiefComplaintSlice, fhirAppointmentDetails])
  useEffect(() => {
    if (
      IPDchiefComplaintSlice.resultsAvailable &&
      IPDchiefComplaintSlice.chiefCondition
    ) {
      updateChiefComplaint(
        IPDchiefComplaintSlice.chiefCondition,
        IPDchiefComplaintSlice.associatedCondition ?? [],
        IPDchiefComplaintSlice.conditionsBasedObservations
      )
    }
    if (IPDchiefComplaintSlice.noResultsAvailable) {
      setChiefDataArrayData([])
      setAssiChiefDataArrayData([])
    }
  }, [IPDchiefComplaintSlice])

  const updateChiefComplaint = (
    condition: R4.ICondition[],
    associated: R4.ICondition[],
    conditionWiseCategorizedObservations?: { [key: string]: R4.IObservation[] }
  ) => {
    const chiefDataArray: ChiefComplaintsData[] = []

    for (let i = 0; i < condition.length; i++) {
      const assichiefDataArray: AssociatedComplaintData[] = []
      const locationArr: LocationData[] = []
      const evidenceArr: ChiefComplaintData[] = []
      const conditionData: R4.ICondition = condition[i]
      let locationData: R4.ICoding[] = []
      let evidenceData: R4.ICoding[] = []

      if (
        conditionData.bodySite?.length &&
        conditionData.bodySite?.length > 0
      ) {
        const location: R4.ICoding[] = conditionData.bodySite?.map(
          (val) => val.coding?.[0] ?? {}
        )
        locationData = location
        logger.info('inside use updateChiefComplaint', location)
        setLocations(location)
      }

      if (conditionData.evidence && conditionData.evidence.length > 0) {
        if (
          conditionData.evidence[0].code &&
          conditionData.evidence[0].code.length > 0
        ) {
          const eviArr: R4.ICoding[] = []
          for (let m = 0; m < conditionData.evidence.length; m++) {
            const evi: R4.ICoding[] | undefined = conditionData.evidence[
              m
            ].code?.map((val) => val.coding?.[0] ?? {})

            if (evi) {
              eviArr.push(evi[0])
            }

            evidenceData = eviArr
          }
        }
      }

      if (associated.length > 0) {
        const filteredData = associated.filter(
          (d) =>
            getValueRefValueExtensionsOfUrl(
              d.extension,
              'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-chief-complaint-ext'
            )?.split('/')[1]! === conditionData.id!
        )

        if (filteredData.length > 0) {
          for (let j = 0; j < filteredData.length; j++) {
            const conditionDataAssociated: R4.ICondition = filteredData[j]
            if (conditionDataAssociated.id !== undefined) {
              const additional: AssociatedComplaintData = {
                id: 0,
                typeData: conditionDataAssociated.onsetAge
                  ? getUnitAsStringForVal(
                      conditionDataAssociated.onsetAge.unit ?? ''
                    )
                  : conditionDataAssociated.onsetDateTime
                  ? 'custom'
                  : '',
                cui: conditionDataAssociated.code?.coding?.[0],
                preselectedAssociated:
                  conditionDataAssociated.code as ChiefComplaintTerminology,
                days: conditionDataAssociated.onsetAge
                  ? getUnitAsString(
                      conditionDataAssociated.onsetAge.unit ?? ''
                    ) === 'Days'
                    ? conditionDataAssociated.onsetAge.value ?? 0
                    : 0
                  : 0,
                weeks: conditionDataAssociated.onsetAge
                  ? getUnitAsString(
                      conditionDataAssociated.onsetAge.unit ?? ''
                    ) === 'Weeks'
                    ? conditionDataAssociated.onsetAge.value ?? 0
                    : 0
                  : 0,
                months: conditionDataAssociated.onsetAge
                  ? getUnitAsString(
                      conditionDataAssociated.onsetAge.unit ?? ''
                    ) === 'Months'
                    ? conditionDataAssociated.onsetAge.value ?? 0
                    : 0
                  : 0,
                year: conditionDataAssociated.onsetAge
                  ? getUnitAsString(
                      conditionDataAssociated.onsetAge.unit ?? ''
                    ) === 'Years'
                    ? conditionDataAssociated.onsetAge.value ?? 0
                    : 0
                  : 0,
                date: conditionDataAssociated.onsetDateTime
                  ? moment(conditionDataAssociated.onsetDateTime).toDate()
                  : new Date(),
                preselectedId: conditionDataAssociated.id ?? '',
                patientWording: conditionDataAssociated.note
                  ? hasNotesForChiefForPatientText(
                      conditionDataAssociated.note!
                    ).trim()
                  : '',
                anyOther: false,
                anyOtherData: '',
                checked: false,
              }

              assichiefDataArray.push(additional)
            }
          }
        }
      }
      if (evidenceData.length > 0) {
        for (let j = 0; j < evidenceData.length; j++) {
          const evi = {
            cui: evidenceData[j].code ?? '',

            type: '',
            icd: titleCase(evidenceData[j].display ?? ''),
            synonyms: titleCase(evidenceData[j].display ?? ''),
          }
          evidenceArr.push(evi)
        }
      }

      if (locationData.length > 0) {
        for (let j = 0; j < locationData.length; j++) {
          const loc = {
            cui: '',
            snomed_ct: '',
            display: locationData[j].display ?? '',
          }
          locationArr.push(loc)
        }
      }

      if (conditionData.severity) {
        setSeverity(conditionData.severity.coding?.[0])
      }
      if (conditionData.onsetDateTime) {
        setSelectedDate(moment(conditionData.onsetDateTime).toDate())
      }
      if (
        getStringValueExtensionsOfUrl(
          conditionData.extension,
          'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/AllevatingFactor'
        )
      ) {
        setAlleviating(
          getStringValueExtensionsOfUrl(
            conditionData.extension,
            'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/AllevatingFactor'
          )!
        )
      }
      if (
        getStringValueExtensionsOfUrl(
          conditionData.extension,
          'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Character'
        )
      ) {
        setCharacter(
          getStringValueExtensionsOfUrl(
            conditionData.extension,
            'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Character'
          )!
        )
      }
      if (
        getStringValueExtensionsOfUrl(
          conditionData.extension,
          'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Radiation'
        )
      ) {
        setRadiation(
          getStringValueExtensionsOfUrl(
            conditionData.extension,
            'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Radiation'
          )!
        )
      }
      if (
        getStringValueExtensionsOfUrl(
          conditionData.extension,
          'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Temporal'
        )
      ) {
        setTemporal(
          getStringValueExtensionsOfUrl(
            conditionData.extension,
            'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Temporal'
          )!
        )
      }

      if (
        getStringValueExtensionsOfUrl(
          conditionData.extension,
          'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Duration'
        )
      ) {
        setDuration(
          getStringValueExtensionsOfUrl(
            conditionData.extension,
            'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Duration'
          )!
        )
      }
      if (conditionData.meta) {
        setLastUpdated(
          moment(conditionData.meta.lastUpdated ?? '').format('Do MMMM, YYYY')
        )
      }
      const cat: string =
        conditionData.category && conditionData.category[0].coding
          ? conditionData.category[0].coding[0].code ?? ''
          : ''

      let doshaObservation: R4.IObservation | undefined
      let dushyaObservation: R4.IObservation | undefined
      let malaObservation: R4.IObservation | undefined

      console.log(conditionWiseCategorizedObservations)
      if (
        conditionWiseCategorizedObservations &&
        conditionWiseCategorizedObservations[conditionData.id!]
      ) {
        malaObservation = conditionWiseCategorizedObservations[
          conditionData.id!
        ].find(
          (observation) => observation.code?.coding?.[0].code === 'ITA-2.4.1'
        )

        doshaObservation = conditionWiseCategorizedObservations[
          conditionData.id!
        ].find(
          (observation) => observation.code?.coding?.[0].code === 'ITA-2.1.2'
        )
        dushyaObservation = conditionWiseCategorizedObservations[
          conditionData.id!
        ].find(
          (observation) => observation.code?.coding?.[0].code === 'ITA-2.2.2'
        )
      }

      if (cat !== 'associated-complaint') {
        if (conditionData.recordedDate === undefined) {
          getUpdatedChiefComplaints(conditionData)
        }
        chiefDataArray.push({
          preselectedComplaints:
            conditionData.code as ChiefComplaintTerminology,
          preselectedSymptoms:
            conditionData.evidence && conditionData.evidence.length > 0
              ? conditionData.evidence[0].code!
              : [],
          preSelectedLocation: locationArr,
          collapsed: conditionData.meta
            ? moment(
                moment(conditionData.meta.lastUpdated ?? '').format(
                  'YYYY-MM-DD'
                )
              ).isSame(moment().format('YYYY-MM-DD'))
            : false,
          preSelectedDuration: {
            cui:
              getStringValueExtensionsOfUrl(
                conditionData.extension,
                'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Duration'
              ) ?? ''.length > 0
                ? getCodeValueForChiefComplaints(
                    conditionData.extension,
                    'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Duration'
                  ) ?? ''
                : '',
            snomed_ct: 'http://unitsofmeasure.org',
            display:
              getStringValueExtensionsOfUrl(
                conditionData.extension,
                'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Duration'
              ) ?? '',
            label:
              getStringValueExtensionsOfUrl(
                conditionData.extension,
                'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Duration'
              ) ?? '',
          },
          id: conditionData.id ?? '',
          selectedCondition: conditionData.code as ChiefComplaintTerminology,
          selectedSeverity: conditionData.severity
            ? conditionData.severity.coding?.[0]
            : undefined,
          selectedLocation: locationData,
          duration:
            getStringValueExtensionsOfUrl(
              conditionData.extension,
              'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Duration'
            ) ?? '',
          character: getStringValueExtensionsOfUrl(
            conditionData.extension,
            'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Character'
          )!,
          alleviating: getStringValueExtensionsOfUrl(
            conditionData.extension,
            'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/AllevatingFactor'
          )!,
          radiation: getStringValueExtensionsOfUrl(
            conditionData.extension,
            'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Radiation'
          )!,
          temporal:
            getStringValueExtensionsOfUrl(
              conditionData.extension,
              'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Temporal'
            ) ?? '',
          lastUpdated: updatedDateCondition
            ? updatedDateCondition.meta
              ? moment(updatedDateCondition.meta.lastUpdated ?? '').format(
                  'Do MMMM YYYY hh:mm A'
                )
              : ''
            : conditionData.recordedDate
            ? moment(conditionData.recordedDate ?? '').format(
                'Do MMMM YYYY hh:mm A'
              )
            : conditionData.meta
            ? moment(conditionData.meta.lastUpdated ?? '').format(
                'Do MMMM YYYY hh:mm A'
              )
            : '',
          selectedDate: conditionData.onsetDateTime
            ? moment(conditionData.onsetDateTime).toDate()
            : null,
          itemNumber: i + 1,
          notes: hasNotesForChiefForNote(conditionData.note!),
          patientFeeling: hasNotesForChiefForPatient(conditionData.note!),
          category:
            conditionData.category && conditionData.category[0].coding
              ? conditionData.category[0].coding[0].code ?? ''
              : '',
          evidenceData:
            conditionData.evidence && conditionData.evidence.length > 0
              ? conditionData.evidence[0].code!
              : [],
          selectedEvidence: evidenceData,
          preselectedVal: conditionData.onsetAge
            ? conditionData.onsetAge.value ?? 0
            : 0,
          preSelectedUnitType: conditionData.onsetAge
            ? getUnitAsString(conditionData.onsetAge.unit ?? '')
            : conditionData.onsetDateTime
            ? 'custom'
            : '',
          unitValForDays: conditionData.onsetAge
            ? getUnitAsString(conditionData.onsetAge.unit ?? '') === 'Days'
              ? conditionData.onsetAge.value ?? 0
              : 0
            : 0,
          unitValForMonth: conditionData.onsetAge
            ? getUnitAsString(conditionData.onsetAge.unit ?? '') === 'Months'
              ? conditionData.onsetAge.value ?? 0
              : 0
            : 0,
          unitValForYear: conditionData.onsetAge
            ? getUnitAsString(conditionData.onsetAge.unit ?? '') === 'Years'
              ? conditionData.onsetAge.value ?? 0
              : 0
            : 0,
          unitValForWeek: conditionData.onsetAge
            ? getUnitAsString(conditionData.onsetAge.unit ?? '') === 'Weeks'
              ? conditionData.onsetAge.value ?? 0
              : 0
            : 0,
          unitTypeDays: conditionData.onsetAge
            ? getUnitAsStringForVal(conditionData.onsetAge.unit ?? '')
            : conditionData.onsetDateTime
            ? 'custom'
            : '',
          associatedCondition: assichiefDataArray,
          painChecked:
            getStringValueExtensionsOfUrl(
              conditionData.extension,
              'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Duration'
            )!.length > 0 ||
            getStringValueExtensionsOfUrl(
              conditionData.extension,
              'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/AllevatingFactor'
            )!.length > 0 ||
            getStringValueExtensionsOfUrl(
              conditionData.extension,
              'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Character'
            )!.length > 0 ||
            getStringValueExtensionsOfUrl(
              conditionData.extension,
              'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Radiation'
            )!.length > 0 ||
            getStringValueExtensionsOfUrl(
              conditionData.extension,
              'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Temporal'
            )!.length > 0 ||
            evidenceArr.length > 0,
          patientWording: conditionData.note
            ? hasNotesForChiefForPatientText(conditionData.note!).trim()
            : '',
          associatedChecked: assichiefDataArray.length > 0,
          painDuration: parseInt(
            getStringValueExtensionsOfUrlForDurationVal(
              conditionData.extension,
              'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Duration'
            ) ?? '1',
            10
          ),
          notesData: hasNotesForChiefForNote(conditionData.note!)
            ? hasNotesForChiefForNote(conditionData.note!)?.text ?? ''
            : '',
          malaObservation,
          doshaObservation,
          dushyaObservation,
        })
      }
      setChiefDataArrayData(chiefDataArray)
      //   setAssiChiefDataArrayData(assichiefDataArray)
    }
  }

  function handleCollapse(rate: boolean, index: number) {
    const values: ChiefComplaintsData[] = [...chiefDataArrayData]
    values[index].collapsed = rate
    setChiefDataArrayData(values)
  }
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Box display='flex' flexDirection='column' width='100%' flexGrow>
        <Box display='flex' width='100%' flexGrow flexDirection='row' py={0.5}>
          <ReactVisibilitySensor
            offset={{
              top: 100,
              bottom: 30,
            }}
            onChange={(isVisible) => {
              if (isVisible) {
                dispatch(
                  setSelectedSection({ selectedSection: 'chief_complaint' })
                )
              }
            }}
          >
            <Box
              display='flex'
              width='100%'
              flexDirection='column'
              id='chief_complaint'
              flexGrow
            >
              <Box
                display='flex'
                width='100%'
                flexGrow
                flexDirection='row'
                py={0.5}
              >
                <Box display='flex' flexDirection='column'>
                  <Box flexGrow={1} py={1.5}>
                    <Typography variant='subtitle1'>
                      {' '}
                      {/* {t('labelCondition:attr_labels.Condition.code')}{' '} */}
                      {t('labelCommon:Chief Complaint')}
                    </Typography>
                  </Box>
                  {status !== 'completed' && splitview === false && (
                    <Box display='flex' flexGrow flexDirection='row' py={0.5}>
                      <Tooltip title='' id='chief_id_33'>
                        <IconButton
                          aria-label='btn_ord_cancel'
                          color='primary'
                          onClick={() => {
                            setAddChiefComplaints(true)
                          }}
                          id='chief_id_34'
                          style={{ padding: 0 }}
                        >
                          <Typography
                            variant='subtitle2'
                            color='primary'
                            style={{ fontSize: 13 }}
                            component={Link}
                            id='chief_id_36'
                          >
                            {' '}
                            {t(
                              'labelCondition:attr_labels.Condition.code'
                            )}{' '}
                          </Typography>
                          <AddCircleIcon
                            style={{ height: '22px', padding: 0 }}
                            color='primary'
                            id='chief_id_35'
                          />{' '}
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                  <Box>
                    {(IPDchiefComplaintSlice.updatingConditions ||
                      IPDchiefComplaintSlice.searchingConditions) && (
                      <CircularProgress size={15} />
                    )}
                  </Box>
                </Box>
              </Box>

              {IPDchiefComplaintSlice.errorWhileSearchingProcedures && (
                <Box flexGrow width='100%' display='flex' flexDirection='row'>
                  <Typography
                    variant='subtitle2'
                    color='error'
                    style={{
                      fontWeight: 400,
                    }}
                  >
                    {IPDchiefComplaintSlice.errorReason ??
                      'Error while fetching chief complaints'}
                  </Typography>
                </Box>
              )}
              {IPDchiefComplaintSlice.noResultsAvailable && (
                <Box>
                  <Box flexGrow width='100%' display='flex' flexDirection='row'>
                    <Typography
                      variant='subtitle2'
                      style={{
                        fontWeight: 400,
                      }}
                    >
                      {' '}
                      {isUnitAdmin() || splitview ? 'No data available' : ''}
                    </Typography>
                  </Box>
                </Box>
              )}
              {IPDchiefComplaintSlice.resultsAvailable &&
                IPDchiefComplaintSlice.chiefCondition && (
                  <Box>
                    {chiefDataArrayData.length === 0 && splitview === false && (
                      <Box
                        display='flex'
                        width='100%'
                        flexDirection='row'
                        py={1}
                      >
                        <Tooltip title='' id='chief_id_37'>
                          <IconButton
                            aria-label='btn_ord_cancel'
                            color='primary'
                            id='chief_id_38'
                            style={{ padding: 0 }}
                            onClick={() => {
                              setAddChiefComplaints(true)
                            }}
                          >
                            <Typography
                              variant='subtitle2'
                              color='primary'
                              component={Link}
                              id='chief_id_40'
                            >
                              {' '}
                              {t(
                                'labelCondition:attr_labels.Condition.code'
                              )}{' '}
                            </Typography>
                            <AddCircleIcon
                              style={{ height: '22px', padding: 0 }}
                              color='primary'
                              id='chief_id_39'
                            />{' '}
                          </IconButton>
                        </Tooltip>

                        {/* <Box py={1}>
                        <Typography
                          variant='subtitle2'
                          color='primary'
                          component={Link}
                        >
                          {' '}
                          Chief Complaints{' '}
                        </Typography>
                      </Box> */}
                      </Box>
                    )}
                    {/* {splitview === true && (
                      <Typography
                        variant='subtitle2'
                        style={{
                          fontWeight: 400,
                        }}
                      >
                        No Data Available
                      </Typography>
                    )} */}
                  </Box>
                )}
              {IPDchiefComplaintSlice.resultsAvailable &&
                IPDchiefComplaintSlice.chiefCondition && (
                  <Box>
                    {chiefDataArrayData.map((val, index: number) => (
                      <Box key={`med_cond${val.id}`} width='100%' py={1}>
                        <Box>
                          <Box
                            paddingX={1}
                            borderRadius={2}
                            style={{
                              backgroundColor: 'lightGrey',
                            }}
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            height={40}
                          >
                            <Box
                              justifyContent='flex-start'
                              display='flex'
                              flexGrow={1}
                              alignItems='center'
                            >
                              <Typography
                                variant='subtitle1'
                                style={{
                                  color: 'black',

                                  fontWeight: 'bold',
                                }}
                              >
                                Recorded on {val.lastUpdated}
                              </Typography>
                            </Box>
                            <Box
                              justifyContent='flex-end'
                              display='flex'
                              flexGrow={1}
                              alignItems='center'
                            >
                              <Box px={1}>
                                <Tooltip title='' id='chief_id_41'>
                                  <IconButton
                                    aria-label='collapse_order_type'
                                    size='small'
                                    id='chief_id_42'
                                    onClick={() => {
                                      handleCollapse(!val.collapsed, index)
                                    }}
                                  >
                                    {val.collapsed && (
                                      <ArrowDropUpOutlined id='chief_id_43' />
                                    )}
                                    {!val.collapsed && (
                                      <ArrowDropDownOutlined id='chief_id_44' />
                                    )}
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Box>
                          </Box>
                          <Collapse
                            in={val.collapsed}
                            style={{
                              width: '100%',
                            }}
                          >
                            <Box
                              border={2}
                              style={{
                                boxShadow: '0px 0px 4px #ccc',
                                margin: '4px 0px',
                                borderRadius: '4px',
                                borderColor: '#F8F8F8',
                                width: '100%',
                                height: 'auto',
                                backgroundColor: '#F8F8F8',
                              }}
                            >
                              <Grid container>
                                <Grid item xs={3}>
                                  <Box
                                    display='flex'
                                    flexDirection='Column'
                                    width='100%'
                                    style={{
                                      backgroundColor: 'white',
                                    }}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Chief Complaints
                                          </Typography>
                                        </Box>

                                        <Divider
                                          orientation='horizontal'
                                          flexItem
                                          style={{
                                            height: '2px',
                                          }}
                                        />
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={2.5}
                                          style={{
                                            backgroundColor:
                                              kDialogueBackground,
                                          }}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Mapped Symptoms
                                          </Typography>
                                        </Box>

                                        <Divider
                                          orientation='horizontal'
                                          flexItem
                                          style={{
                                            height: '2px',
                                          }}
                                        />
                                        {val.associatedCondition &&
                                          val.associatedCondition.length >
                                            0 && (
                                            <Box
                                              display='flex'
                                              flexDirection='column'
                                            >
                                              {val.associatedCondition.map(
                                                (
                                                  valCondition,
                                                  index1: number
                                                ) => (
                                                  <Box
                                                    key={`med_cond${val.id}`}
                                                    width='100%'
                                                  >
                                                    <Box
                                                      justifyContent='flex-start'
                                                      display='flex'
                                                      flexGrow
                                                      height={30}
                                                      alignItems='center'
                                                      px={1}
                                                    >
                                                      <Typography
                                                        variant='subtitle2'
                                                        // color='initial'
                                                        style={{
                                                          fontFamily:
                                                            'Open Sans',
                                                          fontSize: 12,
                                                          color: 'black',

                                                          fontWeight: 'bold',
                                                          lineHeight: 'normal',
                                                        }}
                                                      >
                                                        Associated Complaints
                                                      </Typography>
                                                    </Box>

                                                    <Divider
                                                      orientation='horizontal'
                                                      flexItem
                                                      style={{
                                                        height: '2px',
                                                      }}
                                                    />
                                                    <Box
                                                      justifyContent='flex-start'
                                                      display='flex'
                                                      flexGrow
                                                      height={30}
                                                      alignItems='center'
                                                      px={2.5}
                                                      style={{
                                                        backgroundColor:
                                                          kDialogueBackground,
                                                      }}
                                                    >
                                                      <Typography
                                                        variant='subtitle2'
                                                        // color='initial'
                                                        style={{
                                                          fontFamily:
                                                            'Open Sans',
                                                          fontSize: 12,
                                                          color: 'black',

                                                          fontWeight: 'bold',
                                                          lineHeight: 'normal',
                                                        }}
                                                      >
                                                        Mapped Symptoms
                                                      </Typography>
                                                    </Box>

                                                    <Divider
                                                      orientation='horizontal'
                                                      flexItem
                                                      style={{
                                                        height: '2px',
                                                      }}
                                                    />
                                                  </Box>
                                                )
                                              )}
                                            </Box>
                                          )}

                                        {val.selectedLocation &&
                                          val.selectedLocation.length > 0 && (
                                            <Box
                                              display='flex'
                                              flexDirection='column'
                                            >
                                              <Box
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                height={30}
                                                alignItems='center'
                                                px={1}
                                              >
                                                <Typography
                                                  variant='subtitle2'
                                                  // color='initial'
                                                  style={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 12,
                                                    color: 'black',

                                                    fontWeight: 'bold',
                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  Pain Profile : Pain Area ?
                                                </Typography>
                                              </Box>

                                              <Divider
                                                orientation='horizontal'
                                                flexItem
                                                style={{
                                                  height: '2px',
                                                }}
                                              />
                                            </Box>
                                          )}
                                        {val.character.length > 0 && (
                                          <Box
                                            display='flex'
                                            flexDirection='column'
                                          >
                                            <Box
                                              justifyContent='flex-start'
                                              display='flex'
                                              flexGrow
                                              height={30}
                                              alignItems='center'
                                              px={1}
                                              style={{
                                                backgroundColor:
                                                  kDialogueBackground,
                                              }}
                                            >
                                              <Typography
                                                variant='subtitle2'
                                                // color='initial'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,
                                                  color: 'black',

                                                  fontWeight: 'bold',
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                Describe what the patient is
                                                experiencing?
                                              </Typography>
                                            </Box>

                                            <Divider
                                              orientation='horizontal'
                                              flexItem
                                              style={{
                                                height: '2px',
                                              }}
                                            />
                                          </Box>
                                        )}
                                        {val.alleviating.length > 0 && (
                                          <Box
                                            display='flex'
                                            flexDirection='column'
                                          >
                                            <Box
                                              justifyContent='flex-start'
                                              display='flex'
                                              flexGrow
                                              alignItems='center'
                                              height={40}
                                              px={1}
                                            >
                                              <Typography
                                                variant='subtitle2'
                                                // color='initial'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,
                                                  color: 'black',

                                                  fontWeight: 'bold',
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                Alleviating/Aggravating factors:
                                                What makes it better and worse?
                                              </Typography>
                                            </Box>

                                            <Divider
                                              orientation='horizontal'
                                              flexItem
                                              style={{
                                                height: '2px',
                                              }}
                                            />
                                          </Box>
                                        )}

                                        {val.radiation.length > 0 && (
                                          <Box
                                            display='flex'
                                            flexDirection='column'
                                          >
                                            <Box
                                              justifyContent='flex-start'
                                              display='flex'
                                              flexGrow
                                              alignItems='center'
                                              height={40}
                                              px={1}
                                              style={{
                                                backgroundColor:
                                                  kDialogueBackground,
                                              }}
                                            >
                                              <Typography
                                                variant='subtitle2'
                                                // color='initial'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,
                                                  color: 'black',

                                                  fontWeight: 'bold',
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                {t('labelCommon:radiations')}
                                              </Typography>
                                            </Box>

                                            <Divider
                                              orientation='horizontal'
                                              flexItem
                                              style={{
                                                height: '2px',
                                              }}
                                            />
                                          </Box>
                                        )}

                                        {val.evidenceData &&
                                          val.evidenceData.length > 0 && (
                                            <Box
                                              display='flex'
                                              flexDirection='column'
                                            >
                                              <Box
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                alignItems='center'
                                                minHeight={30}
                                                px={1}
                                              >
                                                <Typography
                                                  variant='subtitle2'
                                                  // color='initial'
                                                  style={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 12,
                                                    color: 'black',

                                                    fontWeight: 'bold',
                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  Associated Symptoms
                                                </Typography>
                                              </Box>

                                              <Divider
                                                orientation='horizontal'
                                                flexItem
                                                style={{
                                                  height: '2px',
                                                }}
                                              />
                                            </Box>
                                          )}
                                        {val.temporal.length > 0 && (
                                          <Box
                                            display='flex'
                                            flexDirection='column'
                                          >
                                            <Box
                                              justifyContent='flex-start'
                                              display='flex'
                                              flexGrow
                                              height={40}
                                              alignItems='center'
                                              px={1}
                                              style={{
                                                backgroundColor:
                                                  val.evidenceData &&
                                                  val.evidenceData.length > 0
                                                    ? kDialogueBackground
                                                    : 'white',
                                              }}
                                            >
                                              <Typography
                                                variant='subtitle2'
                                                // color='initial'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,
                                                  color: 'black',

                                                  fontWeight: 'bold',
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                {t('labelCommon:pattern')}
                                              </Typography>
                                            </Box>

                                            <Divider
                                              orientation='horizontal'
                                              flexItem
                                              style={{
                                                height: '2px',
                                              }}
                                            />
                                          </Box>
                                        )}
                                        {val.selectedSeverity && (
                                          <Box
                                            display='flex'
                                            flexDirection='column'
                                            style={{
                                              backgroundColor:
                                                val.temporal.length > 0 &&
                                                val.notes === undefined
                                                  ? val.evidenceData &&
                                                    val.evidenceData.length > 0
                                                    ? 'white'
                                                    : kDialogueBackground
                                                  : 'white',
                                            }}
                                          >
                                            <Box
                                              justifyContent='flex-start'
                                              display='flex'
                                              flexGrow
                                              height={30}
                                              alignItems='center'
                                              px={1}
                                            >
                                              <Typography
                                                variant='subtitle2'
                                                // color='initial'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,
                                                  color: 'black',

                                                  fontWeight: 'bold',
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                {t(
                                                  'labelCondition:attr_labels.Condition.severity'
                                                )}{' '}
                                              </Typography>
                                            </Box>
                                            {val.notes && (
                                              <Divider
                                                orientation='horizontal'
                                                flexItem
                                                style={{
                                                  height: '2px',
                                                }}
                                              />
                                            )}
                                          </Box>
                                        )}
                                        {val.notes && (
                                          <Box
                                            display='flex'
                                            flexDirection='column'
                                          >
                                            <Box
                                              justifyContent='flex-start'
                                              display='flex'
                                              flexGrow
                                              height={30}
                                              alignItems='center'
                                              px={1}
                                              style={{
                                                backgroundColor:
                                                  kDialogueBackground,
                                              }}
                                            >
                                              <Typography
                                                variant='subtitle2'
                                                // color='initial'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,
                                                  color: 'black',

                                                  fontWeight: 'bold',
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                Additional Notes
                                              </Typography>
                                            </Box>

                                            {/* <Divider
                                            orientation='horizontal'
                                            flexItem
                                            style={{
                                              height: '2px',
                                            }}
                                          /> */}
                                          </Box>
                                        )}
                                      </Box>

                                      <Box
                                        display='flex'
                                        flexGrow={1}
                                        justifyContent='flex-end'
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: 'auto',
                                            color: 'red',
                                            width: '2px',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Box>
                                </Grid>

                                <Grid
                                  item
                                  xs={9}
                                  style={{
                                    backgroundColor: 'white',
                                  }}
                                >
                                  <Box
                                    display='flex'
                                    flexDirection='Column'
                                    width='100%'
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        {/*     Main Start  */}
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          {val.patientFeeling && (
                                            <OverflowTypography
                                              text={
                                                val.patientFeeling.text ?? ''
                                              }
                                              typographyProps={{
                                                variant: 'subtitle2',
                                                color: 'initial',
                                              }}
                                            />
                                          )}
                                        </Box>

                                        <Divider
                                          orientation='horizontal'
                                          flexItem
                                          style={{
                                            height: '2px',
                                          }}
                                        />
                                        {/*     Main end  */}

                                        <Box
                                          display='flex'
                                          flexDirection='row'
                                          width='100%'
                                          style={{
                                            backgroundColor:
                                              kDialogueBackground,
                                          }}
                                        >
                                          <Box
                                            justifyContent='flex-start'
                                            display='flex'
                                            flexGrow
                                            height={30}
                                            alignItems='center'
                                            px={1}
                                            width='40%'
                                          >
                                            {val.selectedCondition &&
                                              val.selectedCondition.text && (
                                                <Box
                                                  display='flex'
                                                  flexDirection='column'
                                                  width='100%'
                                                  flexGrow
                                                  overflow='auto'
                                                >
                                                  <Box>
                                                    <Typography
                                                      variant='subtitle2'
                                                      color='initial'
                                                      style={{
                                                        fontFamily: 'Open Sans',
                                                        fontSize: 12,

                                                        fontWeight: 600,
                                                        lineHeight: 'normal',
                                                      }}
                                                    >
                                                      {titleCase(
                                                        val.selectedCondition
                                                          .text
                                                      )}
                                                    </Typography>
                                                  </Box>
                                                </Box>
                                              )}
                                          </Box>
                                          <Box display='flex' width='2%'>
                                            <Divider
                                              orientation='vertical'
                                              flexItem
                                              style={{
                                                height: '30px',

                                                width: '2px',
                                              }}
                                            />
                                          </Box>
                                          <Box
                                            justifyContent='flex-start'
                                            display='flex'
                                            flexGrow
                                            height={30}
                                            alignItems='center'
                                            px={1}
                                            width='29%'
                                          >
                                            <Typography
                                              variant='subtitle2'
                                              //   color='initial'
                                              style={{
                                                fontFamily: 'Open Sans',
                                                fontSize: 12,
                                                color: 'black',

                                                fontWeight: 'bold',
                                                lineHeight: 'normal',
                                              }}
                                            >
                                              Duration / Start Date
                                            </Typography>
                                          </Box>
                                          <Box display='flex' width='2%'>
                                            <Divider
                                              orientation='vertical'
                                              flexItem
                                              style={{
                                                height: '30px',

                                                width: '2px',
                                              }}
                                            />
                                          </Box>
                                          <Box
                                            justifyContent='flex-start'
                                            display='flex'
                                            flexGrow
                                            height={30}
                                            alignItems='center'
                                            px={1}
                                            width='27%'
                                          >
                                            {val.selectedDate && (
                                              <Typography
                                                variant='subtitle2'
                                                color='initial'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,

                                                  fontWeight: 600,
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                {moment(
                                                  val.selectedDate
                                                ).format('Do MMMM, YYYY')}
                                              </Typography>
                                            )}
                                            {val.unitValForDays > 0 && (
                                              <Typography
                                                variant='subtitle2'
                                                color='initial'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,

                                                  fontWeight: 600,
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                {val.unitValForDays}{' '}
                                                {getStringDays(
                                                  val.unitValForDays
                                                )}
                                              </Typography>
                                            )}
                                            {val.unitValForWeek > 0 && (
                                              <Typography
                                                variant='subtitle2'
                                                color='initial'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,

                                                  fontWeight: 600,
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                {val.unitValForWeek}{' '}
                                                {getStringWeeks(
                                                  val.unitValForWeek
                                                )}
                                              </Typography>
                                            )}
                                            {val.unitValForMonth > 0 && (
                                              <Typography
                                                variant='subtitle2'
                                                color='initial'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,

                                                  fontWeight: 600,
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                {val.unitValForMonth}{' '}
                                                {getStringMonth(
                                                  val.unitValForMonth
                                                )}
                                              </Typography>
                                            )}
                                            {val.unitValForYear > 0 && (
                                              <Typography
                                                variant='subtitle2'
                                                color='initial'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,

                                                  fontWeight: 600,
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                {val.unitValForYear}{' '}
                                                {getStringYear(
                                                  val.unitValForYear
                                                )}
                                              </Typography>
                                            )}
                                          </Box>
                                        </Box>

                                        <Divider
                                          orientation='horizontal'
                                          flexItem
                                          style={{
                                            height: '2px',
                                          }}
                                        />

                                        {/*     Associated Start  */}
                                        {val.associatedCondition &&
                                          val.associatedCondition.length >
                                            0 && (
                                            <Box
                                              display='flex'
                                              flexDirection='column'
                                            >
                                              {val.associatedCondition.map(
                                                (
                                                  valCondition,
                                                  index1: number
                                                ) => (
                                                  <Box
                                                    key={`med_cond${val.id}`}
                                                    width='100%'
                                                  >
                                                    <Box
                                                      justifyContent='flex-start'
                                                      display='flex'
                                                      flexGrow
                                                      height={30}
                                                      alignItems='center'
                                                      px={1}
                                                    >
                                                      {valCondition.patientWording && (
                                                        <Box
                                                          display='flex'
                                                          flexDirection='column'
                                                          width='100%'
                                                          flexGrow
                                                        >
                                                          <OverflowTypography
                                                            text={
                                                              valCondition.patientWording ??
                                                              ''
                                                            }
                                                            typographyProps={{
                                                              variant:
                                                                'subtitle2',
                                                              color: 'initial',
                                                            }}
                                                          />
                                                        </Box>
                                                      )}
                                                    </Box>

                                                    <Divider
                                                      orientation='horizontal'
                                                      flexItem
                                                      style={{
                                                        height: '2px',
                                                      }}
                                                    />
                                                    <Box
                                                      display='flex'
                                                      flexDirection='row'
                                                      width='100%'
                                                      style={{
                                                        backgroundColor:
                                                          kDialogueBackground,
                                                      }}
                                                    >
                                                      <Box
                                                        justifyContent='flex-start'
                                                        display='flex'
                                                        flexGrow
                                                        height={30}
                                                        alignItems='center'
                                                        px={1}
                                                        width='40%'
                                                      >
                                                        {valCondition.cui &&
                                                          valCondition.cui
                                                            .display && (
                                                            <Box
                                                              display='flex'
                                                              flexDirection='column'
                                                              width='100%'
                                                              flexGrow
                                                              overflow='auto'
                                                            >
                                                              <Box>
                                                                <Typography
                                                                  variant='subtitle2'
                                                                  color='initial'
                                                                  style={{
                                                                    fontFamily:
                                                                      'Open Sans',
                                                                    fontSize: 12,

                                                                    fontWeight: 600,
                                                                    lineHeight:
                                                                      'normal',
                                                                  }}
                                                                >
                                                                  {titleCase(
                                                                    valCondition
                                                                      .cui
                                                                      .display
                                                                  )}
                                                                </Typography>
                                                              </Box>
                                                            </Box>
                                                          )}
                                                      </Box>
                                                      <Box
                                                        display='flex'
                                                        width='2%'
                                                      >
                                                        <Divider
                                                          orientation='vertical'
                                                          flexItem
                                                          style={{
                                                            height: '30px',

                                                            width: '2px',
                                                          }}
                                                        />
                                                      </Box>
                                                      <Box
                                                        justifyContent='flex-start'
                                                        display='flex'
                                                        flexGrow
                                                        height={30}
                                                        alignItems='center'
                                                        px={1}
                                                        width='29%'
                                                      >
                                                        <Typography
                                                          variant='subtitle2'
                                                          //   color='initial'
                                                          style={{
                                                            fontFamily:
                                                              'Open Sans',
                                                            fontSize: 12,
                                                            color: 'black',
                                                            fontWeight: 'bold',
                                                            lineHeight:
                                                              'normal',
                                                          }}
                                                        >
                                                          Duration / Start Date
                                                        </Typography>
                                                      </Box>
                                                      <Box
                                                        display='flex'
                                                        width='2%'
                                                      >
                                                        <Divider
                                                          orientation='vertical'
                                                          flexItem
                                                          style={{
                                                            height: '30px',

                                                            width: '2px',
                                                          }}
                                                        />
                                                      </Box>
                                                      <Box
                                                        justifyContent='flex-start'
                                                        display='flex'
                                                        flexGrow
                                                        height={30}
                                                        alignItems='center'
                                                        px={1}
                                                        width='27%'
                                                      >
                                                        {valCondition.cui &&
                                                          valCondition.typeData ===
                                                            'custom' &&
                                                          valCondition.date && (
                                                            <Typography
                                                              variant='subtitle2'
                                                              color='initial'
                                                              style={{
                                                                fontFamily:
                                                                  'Open Sans',
                                                                fontSize: 12,

                                                                fontWeight: 600,
                                                                lineHeight:
                                                                  'normal',
                                                              }}
                                                            >
                                                              {moment(
                                                                valCondition.date
                                                              ).format(
                                                                'Do MMMM, YYYY'
                                                              )}
                                                            </Typography>
                                                          )}
                                                        {valCondition.days >
                                                          0 && (
                                                          <Typography
                                                            variant='subtitle2'
                                                            color='initial'
                                                            style={{
                                                              fontFamily:
                                                                'Open Sans',
                                                              fontSize: 12,

                                                              fontWeight: 600,
                                                              lineHeight:
                                                                'normal',
                                                            }}
                                                          >
                                                            {valCondition.days}{' '}
                                                            {getStringDays(
                                                              valCondition.days
                                                            )}
                                                          </Typography>
                                                        )}
                                                        {valCondition.weeks >
                                                          0 && (
                                                          <Typography
                                                            variant='subtitle2'
                                                            color='initial'
                                                            style={{
                                                              fontFamily:
                                                                'Open Sans',
                                                              fontSize: 12,

                                                              fontWeight: 600,
                                                              lineHeight:
                                                                'normal',
                                                            }}
                                                          >
                                                            {valCondition.weeks}{' '}
                                                            {getStringWeeks(
                                                              valCondition.weeks
                                                            )}
                                                          </Typography>
                                                        )}
                                                        {valCondition.months >
                                                          0 && (
                                                          <Typography
                                                            variant='subtitle2'
                                                            color='initial'
                                                            style={{
                                                              fontFamily:
                                                                'Open Sans',
                                                              fontSize: 12,

                                                              fontWeight: 600,
                                                              lineHeight:
                                                                'normal',
                                                            }}
                                                          >
                                                            {
                                                              valCondition.months
                                                            }{' '}
                                                            {getStringMonth(
                                                              valCondition.months
                                                            )}
                                                          </Typography>
                                                        )}
                                                        {valCondition.year >
                                                          0 && (
                                                          <Typography
                                                            variant='subtitle2'
                                                            color='initial'
                                                            style={{
                                                              fontFamily:
                                                                'Open Sans',
                                                              fontSize: 12,

                                                              fontWeight: 600,
                                                              lineHeight:
                                                                'normal',
                                                            }}
                                                          >
                                                            {valCondition.year}{' '}
                                                            {getStringYear(
                                                              valCondition.year
                                                            )}
                                                          </Typography>
                                                        )}
                                                      </Box>
                                                    </Box>
                                                    <Divider
                                                      orientation='horizontal'
                                                      flexItem
                                                      style={{
                                                        height: '2px',
                                                      }}
                                                    />
                                                    {/*     Associated end  */}
                                                  </Box>
                                                )
                                              )}
                                            </Box>
                                          )}

                                        {val.selectedLocation &&
                                          val.selectedLocation.length > 0 && (
                                            <Box
                                              display='flex'
                                              flexDirection='row'
                                              width='100%'
                                            >
                                              <Box
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                height={30}
                                                alignItems='center'
                                                px={1}
                                                width='40%'
                                              >
                                                {val.selectedLocation &&
                                                  val.selectedLocation.length >
                                                    0 && (
                                                    <Box
                                                      display='flex'
                                                      flexDirection='column'
                                                      width='100%'
                                                      flexGrow
                                                      overflow='auto'
                                                    >
                                                      <Box>
                                                        <Typography
                                                          variant='subtitle2'
                                                          color='initial'
                                                          style={{
                                                            fontFamily:
                                                              'Open Sans',
                                                            fontSize: 12,

                                                            fontWeight: 600,
                                                            lineHeight:
                                                              'normal',
                                                          }}
                                                        >
                                                          {titleCase(
                                                            val.selectedLocation
                                                              ?.map(
                                                                (e) =>
                                                                  e.display ??
                                                                  ''
                                                              )
                                                              .join(', ')
                                                          )}
                                                        </Typography>
                                                      </Box>
                                                    </Box>
                                                  )}
                                              </Box>
                                              <Box display='flex' width='2%'>
                                                <Divider
                                                  orientation='vertical'
                                                  flexItem
                                                  style={{
                                                    height: '30px',

                                                    width: '2px',
                                                  }}
                                                />
                                              </Box>
                                              <Box
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                height={30}
                                                alignItems='center'
                                                px={1}
                                                width='29%'
                                              >
                                                <Typography
                                                  variant='subtitle2'
                                                  style={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 12,
                                                    color: 'black',

                                                    fontWeight: 'bold',
                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  Onset Duration
                                                </Typography>
                                              </Box>
                                              <Box display='flex' width='2%'>
                                                <Divider
                                                  orientation='vertical'
                                                  flexItem
                                                  style={{
                                                    height: '30px',

                                                    width: '2px',
                                                  }}
                                                />
                                              </Box>
                                              <Box
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                height={30}
                                                alignItems='center'
                                                px={1}
                                                width='27%'
                                              >
                                                <Typography
                                                  variant='subtitle2'
                                                  color='initial'
                                                  style={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 12,

                                                    fontWeight: 600,
                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  {val.duration}
                                                </Typography>
                                              </Box>
                                            </Box>
                                          )}
                                        {val.selectedLocation &&
                                          val.selectedLocation.length > 0 && (
                                            <Divider
                                              orientation='horizontal'
                                              flexItem
                                              style={{
                                                height: '2px',
                                              }}
                                            />
                                          )}
                                        {val.character.length > 0 && (
                                          <Box
                                            justifyContent='flex-start'
                                            display='flex'
                                            flexGrow
                                            height={30}
                                            alignItems='center'
                                            px={1}
                                            style={{
                                              backgroundColor:
                                                kDialogueBackground,
                                            }}
                                          >
                                            <Box
                                              display='flex'
                                              flexDirection='column'
                                              width='100%'
                                              flexGrow
                                            >
                                              {val.character.length > 0 && (
                                                <OverflowTypography
                                                  text={titleCase(
                                                    val.character
                                                  )}
                                                  typographyProps={{
                                                    variant: 'subtitle2',
                                                    color: 'initial',
                                                  }}
                                                />
                                              )}
                                              {val.character.length === 0 && (
                                                <Typography
                                                  variant='subtitle2'
                                                  color='initial'
                                                  style={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 12,

                                                    fontWeight: 600,
                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  None
                                                </Typography>
                                              )}
                                            </Box>
                                          </Box>
                                        )}
                                        {val.character.length > 0 && (
                                          <Divider
                                            orientation='horizontal'
                                            flexItem
                                            style={{
                                              height: '2px',
                                            }}
                                          />
                                        )}
                                        {val.alleviating.length > 0 && (
                                          <Box
                                            justifyContent='flex-start'
                                            display='flex'
                                            flexGrow
                                            height={40}
                                            alignItems='center'
                                            px={1}
                                          >
                                            <Box
                                              display='flex'
                                              flexDirection='column'
                                              width='100%'
                                              flexGrow
                                            >
                                              {val.alleviating.length === 0 && (
                                                <Typography
                                                  variant='subtitle2'
                                                  color='initial'
                                                  style={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 12,

                                                    fontWeight: 600,
                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  {t(
                                                    'labelCommon:nofactorsFound'
                                                  )}
                                                </Typography>
                                              )}

                                              {val.alleviating.length > 0 && (
                                                <OverflowTypography
                                                  text={titleCase(
                                                    val.alleviating
                                                  )}
                                                  typographyProps={{
                                                    variant: 'subtitle2',
                                                    color: 'initial',
                                                  }}
                                                />
                                              )}
                                            </Box>
                                          </Box>
                                        )}
                                        {val.alleviating.length > 0 && (
                                          <Divider
                                            orientation='horizontal'
                                            flexItem
                                            style={{
                                              height: '2px',
                                            }}
                                          />
                                        )}
                                        {val.radiation.length > 0 && (
                                          <Box
                                            justifyContent='flex-start'
                                            display='flex'
                                            flexDirection='column'
                                            flexGrow
                                            style={{
                                              backgroundColor:
                                                kDialogueBackground,
                                            }}
                                          >
                                            <Box
                                              justifyContent='flex-start'
                                              display='flex'
                                              flexGrow
                                              height={40}
                                              alignItems='center'
                                              px={1}
                                            >
                                              <Box
                                                display='flex'
                                                flexDirection='column'
                                                width='100%'
                                                flexGrow
                                              >
                                                {val.radiation.length === 0 && (
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                    style={{
                                                      fontFamily: 'Open Sans',
                                                      fontSize: 12,

                                                      fontWeight: 600,
                                                      lineHeight: 'normal',
                                                    }}
                                                  >
                                                    None
                                                  </Typography>
                                                )}
                                                {val.radiation.length > 0 && (
                                                  <OverflowTypography
                                                    text={titleCase(
                                                      val.radiation
                                                    )}
                                                    typographyProps={{
                                                      variant: 'subtitle2',
                                                      color: 'initial',
                                                    }}
                                                  />
                                                )}
                                              </Box>
                                            </Box>

                                            <Divider
                                              orientation='horizontal'
                                              flexItem
                                              style={{
                                                height: '2px',
                                              }}
                                            />
                                          </Box>
                                        )}
                                        {val.evidenceData &&
                                          val.evidenceData.length > 0 && (
                                            <Box
                                              justifyContent='flex-start'
                                              display='flex'
                                              flexDirection='column'
                                              flexGrow
                                            >
                                              <Box
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                minHeight={30}
                                                alignItems='center'
                                                px={1}
                                              >
                                                <Box
                                                  display='flex'
                                                  flexDirection='column'
                                                  width='100%'
                                                  flexGrow
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                    style={{
                                                      fontFamily: 'Open Sans',
                                                      fontSize: 12,

                                                      fontWeight: 600,
                                                      lineHeight: 'normal',
                                                    }}
                                                  >
                                                    {titleCase(
                                                      val.evidenceData
                                                        ?.map((e) =>
                                                          titleCase(
                                                            e.text ?? ''
                                                          )
                                                        )
                                                        .join(', ')
                                                    )}
                                                  </Typography>
                                                </Box>
                                              </Box>

                                              <Divider
                                                orientation='horizontal'
                                                flexItem
                                                style={{
                                                  height: '2px',
                                                }}
                                              />
                                            </Box>
                                          )}
                                        {val.temporal.length > 0 && (
                                          <Box
                                            justifyContent='flex-start'
                                            display='flex'
                                            flexDirection='column'
                                            flexGrow
                                            style={{
                                              backgroundColor:
                                                val.evidenceData &&
                                                val.evidenceData.length > 0
                                                  ? kDialogueBackground
                                                  : 'white',
                                            }}
                                          >
                                            <Box
                                              justifyContent='flex-start'
                                              display='flex'
                                              flexGrow
                                              height={40}
                                              alignItems='center'
                                              px={1}
                                            >
                                              <Box
                                                display='flex'
                                                flexDirection='column'
                                                width='100%'
                                                flexGrow
                                              >
                                                {val.temporal.length > 0 && (
                                                  <OverflowTypography
                                                    text={titleCase(
                                                      val.temporal
                                                    )}
                                                    typographyProps={{
                                                      variant: 'subtitle2',
                                                      color: 'initial',
                                                    }}
                                                  />
                                                )}
                                                {val.temporal.length === 0 && (
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                    style={{
                                                      fontFamily: 'Open Sans',
                                                      fontSize: 12,

                                                      fontWeight: 600,
                                                      lineHeight: 'normal',
                                                    }}
                                                  >
                                                    None
                                                  </Typography>
                                                )}
                                              </Box>
                                            </Box>

                                            <Divider
                                              orientation='horizontal'
                                              flexItem
                                              style={{
                                                height: '2px',
                                              }}
                                            />
                                          </Box>
                                        )}
                                        {val.selectedSeverity && (
                                          <Box
                                            display='flex'
                                            flexDirection='column'
                                            style={{
                                              backgroundColor:
                                                val.temporal.length > 0 &&
                                                val.notes === undefined
                                                  ? val.evidenceData &&
                                                    val.evidenceData.length > 0
                                                    ? 'white'
                                                    : kDialogueBackground
                                                  : 'white',
                                            }}
                                          >
                                            <Box
                                              justifyContent='flex-start'
                                              display='flex'
                                              flexGrow
                                              height={30}
                                              alignItems='center'
                                              px={1}
                                            >
                                              <Box
                                                display='flex'
                                                flexDirection='column'
                                                width='100%'
                                                flexGrow
                                              >
                                                <Typography
                                                  variant='subtitle2'
                                                  color='initial'
                                                  style={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 12,

                                                    fontWeight: 600,
                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  {titleCase(
                                                    val.selectedSeverity
                                                      .display ?? 'None'
                                                  )}
                                                </Typography>
                                              </Box>
                                            </Box>
                                            {val.notes && (
                                              <Divider
                                                orientation='horizontal'
                                                flexItem
                                                style={{
                                                  height: '2px',
                                                }}
                                              />
                                            )}
                                          </Box>
                                        )}
                                        {val.notes && (
                                          <Box
                                            justifyContent='flex-start'
                                            display='flex'
                                            flexGrow
                                            height={30}
                                            alignItems='center'
                                            px={1}
                                            style={{
                                              backgroundColor:
                                                kDialogueBackground,
                                            }}
                                          >
                                            {val.notes && (
                                              <Box
                                                display='flex'
                                                flexDirection='row'
                                                width='100%'
                                                flexGrow
                                              >
                                                <OverflowTypography
                                                  text={val.notes.text ?? ''}
                                                  typographyProps={{
                                                    variant: 'subtitle2',
                                                    color: 'initial',
                                                  }}
                                                />
                                              </Box>
                                            )}
                                          </Box>
                                        )}

                                        {/* <Divider
                                        orientation='horizontal'
                                        flexItem
                                        style={{
                                          height: '2px',
                                        }}
                                      /> */}
                                      </Box>
                                      {/* <Box
                                      display='flex'
                                      flexGrow={1}
                                      justifyContent='flex-end'
                                    >
                                      <Divider
                                        orientation='vertical'
                                        flexItem
                                        style={{
                                          height: 'auto',
                                          color: 'red',
                                          width: '2px',
                                        }}
                                      />
                                    </Box> */}
                                    </Box>
                                  </Box>
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    backgroundColor: 'white',
                                  }}
                                >
                                  {val.doshaObservation &&
                                    ((val.doshaObservation.component &&
                                      val.doshaObservation.component.length >
                                        0) ||
                                      getNotesString(
                                        val.doshaObservation?.note ?? []
                                      )) && (
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                      >
                                        <Box
                                          display='flex'
                                          flexDirection='row'
                                          width='100%'
                                          height={30}
                                          alignItems='center'
                                          alignContent='center'
                                          borderRadius={2}
                                          style={{
                                            backgroundColor: 'lightGrey',
                                          }}
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle1'
                                            color='initial'
                                            style={{
                                              color: 'black',

                                              fontWeight: 'bold',
                                            }}
                                          >
                                            {' '}
                                            Dosha
                                          </Typography>
                                        </Box>
                                        <Divider
                                          orientation='horizontal'
                                          flexItem
                                          style={{
                                            height: '2px',
                                          }}
                                        />
                                        <Box
                                          display='flex'
                                          flexDirection='row'
                                          height={30}
                                          alignItems='center'
                                          style={{
                                            backgroundColor:
                                              kDialogueBackground,
                                          }}
                                        >
                                          <Grid
                                            container
                                            direction='row'
                                            style={{
                                              height: '100%',
                                              margin: '0px',
                                              padding: '0px',
                                            }}
                                          >
                                            <Grid
                                              item
                                              xs={6}
                                              style={{
                                                height: '100%',
                                                margin: '0px',

                                                padding: '0px',
                                              }}
                                            >
                                              <Grid
                                                container
                                                direction='row'
                                                style={{
                                                  height: '100%',
                                                  margin: '0px',

                                                  padding: '0px',
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                  >
                                                    Vata
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='body2'
                                                    color='initial'
                                                  >
                                                    {getObservationValueCodeableConceptDisplayByComponent(
                                                      {
                                                        observation:
                                                          val.doshaObservation,
                                                        componentCode:
                                                          'ITA-2.1.1.1',
                                                      }
                                                    )}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>

                                            <Grid
                                              item
                                              xs={6}
                                              style={{
                                                height: '100%',
                                                margin: '0px',

                                                padding: '0px',
                                              }}
                                            >
                                              <Grid
                                                container
                                                direction='row'
                                                style={{
                                                  height: '100%',
                                                  margin: '0px',

                                                  padding: '0px',
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                  >
                                                    {' '}
                                                    Pitta
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',

                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='body2'
                                                    color='initial'
                                                  >
                                                    {getObservationValueCodeableConceptDisplayByComponent(
                                                      {
                                                        observation:
                                                          val.doshaObservation,
                                                        componentCode:
                                                          'ITA-2.1.2.1',
                                                      }
                                                    )}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                        <Box
                                          display='flex'
                                          flexDirection='row'
                                          width='100%'
                                          height='30px'
                                        >
                                          <Grid
                                            container
                                            direction='row'
                                            style={{
                                              height: '100%',
                                              margin: '0px',

                                              padding: '0px',
                                            }}
                                          >
                                            <Grid
                                              item
                                              xs={6}
                                              style={{
                                                height: '100%',
                                                margin: '0px',

                                                padding: '0px',
                                              }}
                                            >
                                              <Grid
                                                container
                                                direction='row'
                                                style={{
                                                  height: '100%',
                                                  margin: '0px',

                                                  padding: '0px',
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                  >
                                                    Kapha
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='body2'
                                                    color='initial'
                                                  >
                                                    {getObservationValueCodeableConceptDisplayByComponent(
                                                      {
                                                        observation:
                                                          val.doshaObservation,
                                                        componentCode:
                                                          'ITA-2.1.3.1',
                                                      }
                                                    )}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={6}
                                              style={{
                                                height: '100%',
                                                margin: '0px',

                                                padding: '0px',
                                              }}
                                            >
                                              <Grid
                                                container
                                                direction='row'
                                                style={{
                                                  height: '100%',
                                                  margin: '0px',

                                                  padding: '0px',
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                  >
                                                    Remarks
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',

                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  {getNotesFromAllergy(
                                                    val.doshaObservation
                                                      ?.note ?? []
                                                  )?.map((eData) => (
                                                    <Box
                                                      key={eData}
                                                      width='100%'
                                                      display='flex'
                                                      flexGrow
                                                      height={30}
                                                      alignItems='center'
                                                    >
                                                      <OverflowTypography
                                                        text={eData ?? ''}
                                                        typographyProps={{
                                                          variant: 'body1',
                                                          color: 'initial',
                                                        }}
                                                      />
                                                    </Box>
                                                  ))}
                                                  {/* <Typography
                                                    variant='body1'
                                                    color='initial'
                                                  >
                                                    {getNotesFromAllergy(
                                                      val.doshaObservation
                                                        ?.note ?? []
                                                    ) ?? ''}
                                                  </Typography> */}
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      </Box>
                                    )}
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    backgroundColor: 'white',
                                  }}
                                >
                                  {val.dushyaObservation &&
                                    ((val.dushyaObservation.component &&
                                      val.dushyaObservation.component.length >
                                        0) ||
                                      getNotesString(
                                        val.dushyaObservation?.note ?? []
                                      )) && (
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                      >
                                        <Box
                                          display='flex'
                                          flexDirection='row'
                                          width='100%'
                                          height={30}
                                          alignItems='center'
                                          alignContent='center'
                                          borderRadius={2}
                                          style={{
                                            backgroundColor: 'lightGrey',
                                          }}
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle1'
                                            color='initial'
                                            style={{
                                              color: 'black',

                                              fontWeight: 'bold',
                                            }}
                                          >
                                            {' '}
                                            Dushya
                                          </Typography>
                                        </Box>
                                        <Divider
                                          orientation='horizontal'
                                          flexItem
                                          style={{
                                            height: '2px',
                                          }}
                                        />
                                        <Box
                                          display='flex'
                                          flexDirection='row'
                                          height={30}
                                          alignItems='center'
                                          style={{
                                            backgroundColor:
                                              kDialogueBackground,
                                          }}
                                        >
                                          <Grid
                                            container
                                            direction='row'
                                            style={{
                                              height: '100%',
                                              margin: '0px',
                                              padding: '0px',
                                            }}
                                          >
                                            <Grid
                                              item
                                              xs={6}
                                              style={{
                                                height: '100%',
                                                margin: '0px',

                                                padding: '0px',
                                              }}
                                            >
                                              <Grid
                                                container
                                                direction='row'
                                                style={{
                                                  height: '100%',
                                                  margin: '0px',

                                                  padding: '0px',
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                  >
                                                    Rasa
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='body2'
                                                    color='initial'
                                                  >
                                                    {getObservationValueCodeableConceptDisplayByComponent(
                                                      {
                                                        observation:
                                                          val.dushyaObservation,
                                                        componentCode:
                                                          'ITA-2.2.4',
                                                      }
                                                    )}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>

                                            <Grid
                                              item
                                              xs={6}
                                              style={{
                                                height: '100%',
                                                margin: '0px',

                                                padding: '0px',
                                              }}
                                            >
                                              <Grid
                                                container
                                                direction='row'
                                                style={{
                                                  height: '100%',
                                                  margin: '0px',

                                                  padding: '0px',
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                  >
                                                    {' '}
                                                    Rakta
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',

                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='body2'
                                                    color='initial'
                                                  >
                                                    {getObservationValueCodeableConceptDisplayByComponent(
                                                      {
                                                        observation:
                                                          val.dushyaObservation,
                                                        componentCode:
                                                          'ITA-2.2.5',
                                                      }
                                                    )}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Box>

                                        <Box
                                          display='flex'
                                          flexDirection='row'
                                          height={30}
                                          alignItems='center'
                                        >
                                          <Grid
                                            container
                                            direction='row'
                                            style={{
                                              height: '100%',
                                              margin: '0px',
                                              padding: '0px',
                                            }}
                                          >
                                            <Grid
                                              item
                                              xs={6}
                                              style={{
                                                height: '100%',
                                                margin: '0px',

                                                padding: '0px',
                                              }}
                                            >
                                              <Grid
                                                container
                                                direction='row'
                                                style={{
                                                  height: '100%',
                                                  margin: '0px',

                                                  padding: '0px',
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                  >
                                                    Mamsa
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='body2'
                                                    color='initial'
                                                  >
                                                    {getObservationValueCodeableConceptDisplayByComponent(
                                                      {
                                                        observation:
                                                          val.dushyaObservation,
                                                        componentCode:
                                                          'ITA-2.2.8',
                                                      }
                                                    )}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>

                                            <Grid
                                              item
                                              xs={6}
                                              style={{
                                                height: '100%',
                                                margin: '0px',

                                                padding: '0px',
                                              }}
                                            >
                                              <Grid
                                                container
                                                direction='row'
                                                style={{
                                                  height: '100%',
                                                  margin: '0px',

                                                  padding: '0px',
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                  >
                                                    Meda
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',

                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='body2'
                                                    color='initial'
                                                  >
                                                    {getObservationValueCodeableConceptDisplayByComponent(
                                                      {
                                                        observation:
                                                          val.dushyaObservation,
                                                        componentCode:
                                                          'ITA-2.2.9',
                                                      }
                                                    )}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                        <Box
                                          display='flex'
                                          flexDirection='row'
                                          height={30}
                                          alignItems='center'
                                          style={{
                                            backgroundColor:
                                              kDialogueBackground,
                                          }}
                                        >
                                          <Grid
                                            container
                                            direction='row'
                                            style={{
                                              height: '100%',
                                              margin: '0px',
                                              padding: '0px',
                                            }}
                                          >
                                            <Grid
                                              item
                                              xs={6}
                                              style={{
                                                height: '100%',
                                                margin: '0px',

                                                padding: '0px',
                                              }}
                                            >
                                              <Grid
                                                container
                                                direction='row'
                                                style={{
                                                  height: '100%',
                                                  margin: '0px',

                                                  padding: '0px',
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                  >
                                                    Asthi
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='body2'
                                                    color='initial'
                                                  >
                                                    {getObservationValueCodeableConceptDisplayByComponent(
                                                      {
                                                        observation:
                                                          val.dushyaObservation,
                                                        componentCode:
                                                          'ITA-2.2.10',
                                                      }
                                                    )}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>

                                            <Grid
                                              item
                                              xs={6}
                                              style={{
                                                height: '100%',
                                                margin: '0px',

                                                padding: '0px',
                                              }}
                                            >
                                              <Grid
                                                container
                                                direction='row'
                                                style={{
                                                  height: '100%',
                                                  margin: '0px',

                                                  padding: '0px',
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                  >
                                                    Majja
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',

                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='body2'
                                                    color='initial'
                                                  >
                                                    {getObservationValueCodeableConceptDisplayByComponent(
                                                      {
                                                        observation:
                                                          val.dushyaObservation,
                                                        componentCode:
                                                          'ITA-2.2.16',
                                                      }
                                                    )}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                        <Box
                                          display='flex'
                                          flexDirection='row'
                                          width='100%'
                                          height='30px'
                                        >
                                          <Grid
                                            container
                                            direction='row'
                                            style={{
                                              height: '100%',
                                              margin: '0px',

                                              padding: '0px',
                                            }}
                                          >
                                            <Grid
                                              item
                                              xs={6}
                                              style={{
                                                height: '100%',
                                                margin: '0px',

                                                padding: '0px',
                                              }}
                                            >
                                              <Grid
                                                container
                                                direction='row'
                                                style={{
                                                  height: '100%',
                                                  margin: '0px',

                                                  padding: '0px',
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                  >
                                                    Shukra
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='body2'
                                                    color='initial'
                                                  >
                                                    {getObservationValueCodeableConceptDisplayByComponent(
                                                      {
                                                        observation:
                                                          val.dushyaObservation,
                                                        componentCode:
                                                          'ITA-2.2.19',
                                                      }
                                                    )}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={6}
                                              style={{
                                                height: '100%',
                                                margin: '0px',

                                                padding: '0px',
                                              }}
                                            >
                                              <Grid
                                                container
                                                direction='row'
                                                style={{
                                                  height: '100%',
                                                  margin: '0px',

                                                  padding: '0px',
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                  >
                                                    Remarks
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',

                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  {getNotesFromAllergy(
                                                    val.dushyaObservation
                                                      ?.note ?? []
                                                  )?.map((eData) => (
                                                    <Box
                                                      key={eData}
                                                      width='100%'
                                                      display='flex'
                                                      flexGrow
                                                      height={30}
                                                      alignItems='center'
                                                    >
                                                      <OverflowTypography
                                                        text={eData ?? ''}
                                                        typographyProps={{
                                                          variant: 'body1',
                                                          color: 'initial',
                                                        }}
                                                      />
                                                    </Box>
                                                  ))}
                                                  {/* <Typography
                                                    variant='body1'
                                                    color='initial'
                                                  >
                                                    {getNotesFromAllergy(
                                                      val.dushyaObservation
                                                        ?.note ?? []
                                                    ) ?? ''}
                                                  </Typography> */}
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      </Box>
                                    )}
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    backgroundColor: 'white',
                                  }}
                                >
                                  {val.dushyaObservation &&
                                    ((val.dushyaObservation.component &&
                                      val.dushyaObservation.component.length >
                                        0) ||
                                      getNotesString(
                                        val.dushyaObservation?.note ?? []
                                      )) && (
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                      >
                                        <Box
                                          display='flex'
                                          flexDirection='row'
                                          width='100%'
                                          height={30}
                                          alignItems='center'
                                          alignContent='center'
                                          borderRadius={2}
                                          style={{
                                            backgroundColor: 'lightGrey',
                                          }}
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle1'
                                            color='initial'
                                            style={{
                                              color: 'black',

                                              fontWeight: 'bold',
                                            }}
                                          >
                                            {' '}
                                            Dushya
                                          </Typography>
                                        </Box>
                                        <Divider
                                          orientation='horizontal'
                                          flexItem
                                          style={{
                                            height: '2px',
                                          }}
                                        />
                                        <Box
                                          display='flex'
                                          flexDirection='row'
                                          height={30}
                                          alignItems='center'
                                          style={{
                                            backgroundColor:
                                              kDialogueBackground,
                                          }}
                                        >
                                          <Grid
                                            container
                                            direction='row'
                                            style={{
                                              height: '100%',
                                              margin: '0px',
                                              padding: '0px',
                                            }}
                                          >
                                            <Grid
                                              item
                                              xs={6}
                                              style={{
                                                height: '100%',
                                                margin: '0px',

                                                padding: '0px',
                                              }}
                                            >
                                              <Grid
                                                container
                                                direction='row'
                                                style={{
                                                  height: '100%',
                                                  margin: '0px',

                                                  padding: '0px',
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                  >
                                                    Rasa
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='body2'
                                                    color='initial'
                                                  >
                                                    {getObservationValueCodeableConceptDisplayByComponent(
                                                      {
                                                        observation:
                                                          val.dushyaObservation,
                                                        componentCode:
                                                          'ITA-2.2.4',
                                                      }
                                                    )}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>

                                            <Grid
                                              item
                                              xs={6}
                                              style={{
                                                height: '100%',
                                                margin: '0px',

                                                padding: '0px',
                                              }}
                                            >
                                              <Grid
                                                container
                                                direction='row'
                                                style={{
                                                  height: '100%',
                                                  margin: '0px',

                                                  padding: '0px',
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                  >
                                                    {' '}
                                                    Rakta
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',

                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='body2'
                                                    color='initial'
                                                  >
                                                    {getObservationValueCodeableConceptDisplayByComponent(
                                                      {
                                                        observation:
                                                          val.dushyaObservation,
                                                        componentCode:
                                                          'ITA-2.2.5',
                                                      }
                                                    )}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Box>

                                        <Box
                                          display='flex'
                                          flexDirection='row'
                                          height={30}
                                          alignItems='center'
                                        >
                                          <Grid
                                            container
                                            direction='row'
                                            style={{
                                              height: '100%',
                                              margin: '0px',
                                              padding: '0px',
                                            }}
                                          >
                                            <Grid
                                              item
                                              xs={6}
                                              style={{
                                                height: '100%',
                                                margin: '0px',

                                                padding: '0px',
                                              }}
                                            >
                                              <Grid
                                                container
                                                direction='row'
                                                style={{
                                                  height: '100%',
                                                  margin: '0px',

                                                  padding: '0px',
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                  >
                                                    Mamsa
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='body2'
                                                    color='initial'
                                                  >
                                                    {getObservationValueCodeableConceptDisplayByComponent(
                                                      {
                                                        observation:
                                                          val.dushyaObservation,
                                                        componentCode:
                                                          'ITA-2.2.8',
                                                      }
                                                    )}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>

                                            <Grid
                                              item
                                              xs={6}
                                              style={{
                                                height: '100%',
                                                margin: '0px',

                                                padding: '0px',
                                              }}
                                            >
                                              <Grid
                                                container
                                                direction='row'
                                                style={{
                                                  height: '100%',
                                                  margin: '0px',

                                                  padding: '0px',
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                  >
                                                    Meda
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',

                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='body2'
                                                    color='initial'
                                                  >
                                                    {getObservationValueCodeableConceptDisplayByComponent(
                                                      {
                                                        observation:
                                                          val.dushyaObservation,
                                                        componentCode:
                                                          'ITA-2.2.9',
                                                      }
                                                    )}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                        <Box
                                          display='flex'
                                          flexDirection='row'
                                          height={30}
                                          alignItems='center'
                                          style={{
                                            backgroundColor:
                                              kDialogueBackground,
                                          }}
                                        >
                                          <Grid
                                            container
                                            direction='row'
                                            style={{
                                              height: '100%',
                                              margin: '0px',
                                              padding: '0px',
                                            }}
                                          >
                                            <Grid
                                              item
                                              xs={6}
                                              style={{
                                                height: '100%',
                                                margin: '0px',

                                                padding: '0px',
                                              }}
                                            >
                                              <Grid
                                                container
                                                direction='row'
                                                style={{
                                                  height: '100%',
                                                  margin: '0px',

                                                  padding: '0px',
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                  >
                                                    Asthi
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='body2'
                                                    color='initial'
                                                  >
                                                    {getObservationValueCodeableConceptDisplayByComponent(
                                                      {
                                                        observation:
                                                          val.dushyaObservation,
                                                        componentCode:
                                                          'ITA-2.2.10',
                                                      }
                                                    )}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>

                                            <Grid
                                              item
                                              xs={6}
                                              style={{
                                                height: '100%',
                                                margin: '0px',

                                                padding: '0px',
                                              }}
                                            >
                                              <Grid
                                                container
                                                direction='row'
                                                style={{
                                                  height: '100%',
                                                  margin: '0px',

                                                  padding: '0px',
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                  >
                                                    Majja
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',

                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='body2'
                                                    color='initial'
                                                  >
                                                    {getObservationValueCodeableConceptDisplayByComponent(
                                                      {
                                                        observation:
                                                          val.dushyaObservation,
                                                        componentCode:
                                                          'ITA-2.2.16',
                                                      }
                                                    )}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                        <Box
                                          display='flex'
                                          flexDirection='row'
                                          width='100%'
                                          height='30px'
                                        >
                                          <Grid
                                            container
                                            direction='row'
                                            style={{
                                              height: '100%',
                                              margin: '0px',

                                              padding: '0px',
                                            }}
                                          >
                                            <Grid
                                              item
                                              xs={6}
                                              style={{
                                                height: '100%',
                                                margin: '0px',

                                                padding: '0px',
                                              }}
                                            >
                                              <Grid
                                                container
                                                direction='row'
                                                style={{
                                                  height: '100%',
                                                  margin: '0px',

                                                  padding: '0px',
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                  >
                                                    Shukra
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='body2'
                                                    color='initial'
                                                  >
                                                    {getObservationValueCodeableConceptDisplayByComponent(
                                                      {
                                                        observation:
                                                          val.dushyaObservation,
                                                        componentCode:
                                                          'ITA-2.2.19',
                                                      }
                                                    )}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={6}
                                              style={{
                                                height: '100%',
                                                margin: '0px',

                                                padding: '0px',
                                              }}
                                            >
                                              <Grid
                                                container
                                                direction='row'
                                                style={{
                                                  height: '100%',
                                                  margin: '0px',

                                                  padding: '0px',
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                  >
                                                    Remarks
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',

                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  {getNotesFromAllergy(
                                                    val.dushyaObservation
                                                      ?.note ?? []
                                                  )?.map((eData) => (
                                                    <Box
                                                      key={eData}
                                                      width='100%'
                                                      display='flex'
                                                      flexGrow
                                                      height={30}
                                                      alignItems='center'
                                                    >
                                                      <OverflowTypography
                                                        text={eData ?? ''}
                                                        typographyProps={{
                                                          variant: 'body1',
                                                          color: 'initial',
                                                        }}
                                                      />
                                                    </Box>
                                                  ))}
                                                  {/* <Typography
                                                    variant='body1'
                                                    color='initial'
                                                  >
                                                    {getNotesFromAllergy(
                                                      val.dushyaObservation
                                                        ?.note ?? []
                                                    ) ?? ''}
                                                  </Typography> */}
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      </Box>
                                    )}
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    backgroundColor: 'white',
                                  }}
                                >
                                  {val.malaObservation &&
                                    ((val.malaObservation.component &&
                                      val.malaObservation.component.length >
                                        0) ||
                                      getNotesString(
                                        val.malaObservation?.note ?? []
                                      )) && (
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                      >
                                        <Box
                                          display='flex'
                                          flexDirection='row'
                                          width='100%'
                                          height={30}
                                          alignItems='center'
                                          alignContent='center'
                                          borderRadius={2}
                                          style={{
                                            backgroundColor: 'lightGrey',
                                          }}
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle1'
                                            color='initial'
                                            style={{
                                              color: 'black',

                                              fontWeight: 'bold',
                                            }}
                                          >
                                            Mala
                                          </Typography>
                                        </Box>
                                        <Divider
                                          orientation='horizontal'
                                          flexItem
                                          style={{
                                            height: '2px',
                                          }}
                                        />
                                        <Box
                                          display='flex'
                                          flexDirection='row'
                                          height={30}
                                          alignItems='center'
                                          style={{
                                            backgroundColor:
                                              kDialogueBackground,
                                          }}
                                        >
                                          <Grid
                                            container
                                            direction='row'
                                            style={{
                                              height: '100%',
                                              margin: '0px',
                                              padding: '0px',
                                            }}
                                          >
                                            <Grid
                                              item
                                              xs={6}
                                              style={{
                                                height: '100%',
                                                margin: '0px',

                                                padding: '0px',
                                              }}
                                            >
                                              <Grid
                                                container
                                                direction='row'
                                                style={{
                                                  height: '100%',
                                                  margin: '0px',

                                                  padding: '0px',
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                  >
                                                    Mutra
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='body2'
                                                    color='initial'
                                                  >
                                                    {getObservationValueCodeableConceptDisplayByComponent(
                                                      {
                                                        observation:
                                                          val.malaObservation,
                                                        componentCode:
                                                          'ITA-2.4.4',
                                                      }
                                                    )}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>

                                            <Grid
                                              item
                                              xs={6}
                                              style={{
                                                height: '100%',
                                                margin: '0px',

                                                padding: '0px',
                                              }}
                                            >
                                              <Grid
                                                container
                                                direction='row'
                                                style={{
                                                  height: '100%',
                                                  margin: '0px',

                                                  padding: '0px',
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                  >
                                                    Purisha
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',

                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='body2'
                                                    color='initial'
                                                  >
                                                    {getObservationValueCodeableConceptDisplayByComponent(
                                                      {
                                                        observation:
                                                          val.malaObservation,
                                                        componentCode:
                                                          'ITA-2.4.3',
                                                      }
                                                    )}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                        <Box
                                          display='flex'
                                          flexDirection='row'
                                          width='100%'
                                          height='30px'
                                        >
                                          <Grid
                                            container
                                            direction='row'
                                            style={{
                                              height: '100%',
                                              margin: '0px',

                                              padding: '0px',
                                            }}
                                          >
                                            <Grid
                                              item
                                              xs={6}
                                              style={{
                                                height: '100%',
                                                margin: '0px',

                                                padding: '0px',
                                              }}
                                            >
                                              <Grid
                                                container
                                                direction='row'
                                                style={{
                                                  height: '100%',
                                                  margin: '0px',

                                                  padding: '0px',
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                  >
                                                    Sweda
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='body2'
                                                    color='initial'
                                                  >
                                                    {getObservationValueCodeableConceptDisplayByComponent(
                                                      {
                                                        observation:
                                                          val.malaObservation,
                                                        componentCode:
                                                          'ITA-2.4.5',
                                                      }
                                                    )}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={6}
                                              style={{
                                                height: '100%',
                                                margin: '0px',

                                                padding: '0px',
                                              }}
                                            >
                                              <Grid
                                                container
                                                direction='row'
                                                style={{
                                                  height: '100%',
                                                  margin: '0px',

                                                  padding: '0px',
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                  >
                                                    Remarks
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',

                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  {getNotesFromAllergy(
                                                    val.malaObservation?.note ??
                                                      []
                                                  )?.map((eData) => (
                                                    <Box
                                                      key={eData}
                                                      width='100%'
                                                      display='flex'
                                                      flexGrow
                                                      height={30}
                                                      alignItems='center'
                                                    >
                                                      <OverflowTypography
                                                        text={eData ?? ''}
                                                        typographyProps={{
                                                          variant: 'body1',
                                                          color: 'initial',
                                                        }}
                                                      />
                                                    </Box>
                                                  ))}
                                                  {/* <Typography
                                                    variant='body1'
                                                    color='initial'
                                                  >
                                                    {getNotesFromAllergy(
                                                      val.malaObservation
                                                        ?.note ?? []
                                                    ) ?? ''}
                                                  </Typography> */}
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      </Box>
                                    )}
                                </Grid>
                              </Grid>
                            </Box>
                          </Collapse>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                )}
            </Box>
          </ReactVisibilitySensor>
        </Box>
      </Box>
      <AddChiefComplanitsIPD
        open={addChiefComplaints}
        fhirAppointmentDetails={fhirAppointmentDetails}
        onClose={() => {
          setAddChiefComplaints(false)
        }}
        onConditionAdded={() => {
          setAddChiefComplaints(false)
          dispatch(resetChiefComplaintDetails)
          //   dispatch(
          //     IPDgetChiefComplaintOfAppointment(fhirAppointmentDetails, splitview)
          //   )
        }}
      />

      {/* <EditChiefComplanits
        open={editChiefComplaints}
        onConditionUpdated={() => {
          setEditChiefComplaints(false)
          dispatch(getChiefComplaintOfAppointment(fhirAppointmentDetails))
        }}
        onClose={() => {
          setEditChiefComplaints(false)
        }}
        fhirAppointmentDetails={fhirAppointmentDetails}
        chiefComplaintsData={chiefComplaintsDetails}
      /> */}
    </MuiPickersUtilsProvider>
  )
}
