/* eslint-disable no-irregular-whitespace */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Typography } from '@mui/material'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useEffect, useState } from 'react'
import ContentShimmer from 'react-content-shimmer'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import 'react-vis/dist/style.css'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { getAccessToken } from 'utils/authHelpers'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import {
  addObservationDetailsForSys,
  deleteObservationDetails,
  getAddStatusForOCA,
  getCarePlanForObs,
  getObservationResourceForPostForSystemicExam,
  getObservationResourceForPostForSystemicExamForGe,
  getObservationsOfSpecificCategoryInEncounter,
} from 'utils/fhirResoureHelpers/observationHelpers'
import { getVendorPartId } from 'utils/routes_helper'
import { OCARenderer } from 'wello-web-components'

interface CardioVascularExaminationsListProps {
  fhirAppointmentDetails: FhirAppointmentDetail

  isReadOnly?: boolean
  carePlan: R4.ICarePlan
}

export const EndoQOL: React.FC<CardioVascularExaminationsListProps> = ({
  fhirAppointmentDetails,

  isReadOnly = false,
  carePlan,
}: CardioVascularExaminationsListProps) => {
  const { t } = useTranslation(['common'])
  const [fetcAgain, setFetchAgain] = useState<string>(getUniqueTempId())
  const [observations, setObservations] = useState<R4.IObservation[]>([])
  const [plan, setPlan] = useState<R4.ICarePlan>()
  const dispatch = useDispatch<any>()
  const [fetchingStatus, setFetchingStatus] = useState<NetworkRequestStatus>({
    initial: true,
  })
  const [loadingStatus, setLoadingStatus] = useState<NetworkRequestStatus>({
    initial: true,
  })

  function getObservationDetails() {
    setFetchingStatus({
      requesting: true,
    })

    getObservationsOfSpecificCategoryInEncounter({
      encounterId: fhirAppointmentDetails.encounter!.id!,
      patient: fhirAppointmentDetails.patient,
      category: 'C0518214',
      observationCode: 'C2266811',
      isForHistory: true,
    })
      .then((e) => {
        if (e) {
          setFetchingStatus({
            requestSuccess: true,
          })
          setObservations(e as R4.IObservation[])
        } else {
          setFetchingStatus({
            requestError: true,
          })
        }
      })
      .catch((e) => {
        setFetchingStatus({
          requestError: true,
        })
      })
  }

  useEffect(() => {
    getObservationDetails()
  }, [])

  return (
    <Box sx={{ width: '100%' }} display='flex' flexDirection='column'>
      {fetchingStatus.requesting && (
        <ContentShimmer
          rows={3}
          size={{
            width: '100%',
            height: '800',
          }}
        />
      )}
      {fetchingStatus.requestError && (
        <Typography variant='body1' color='error'>
          {t('common:errorWhileFetchingData')}
        </Typography>
      )}
      {fetchingStatus.requestSuccess &&
        !fetchingStatus.initial &&
        !fetchingStatus.requesting &&
        !fetchingStatus.requestError && (
          <Box width='100%' display='flex' flexDirection='column' flexGrow={1}>
            <OCARenderer
              OCAFormProviderBaseURL={`${
                process.env.REACT_APP_CC_WORKFLOW_URL
              }${getVendorPartId()}/ocaForms/forms`}
              OCAFormProviderHeader={{
                Authorization: `Bearer ${getAccessToken()}`,

                'Cache-Control': 'no-cache',
              }}
              onSubmit={(
                observation: R4.IObservation,
                onSubmitSuccess?: (data: any) => void
              ) => {
                const updatedObservation =
                  getObservationResourceForPostForSystemicExamForGe(
                    fhirAppointmentDetails.patient.id!,
                    fhirAppointmentDetails.encounter!.id!,
                    [
                      {
                        coding: [
                          {
                            code: 'C0518214',
                            system:
                              'http://terminology.hl7.org/CodeSystem/umls',
                            display: 'Subjective Questionnaire',
                          },
                        ],
                      },
                    ],
                    observation,
                    undefined,
                    carePlan
                  )

                addObservationDetailsForSys(updatedObservation).then((e) => {
                  if (e === false) {
                    dispatch(
                      showErrorAlert('Error while updating observation details')
                    )
                  } else if (
                    (e as R4.IObservation).resourceType === 'Observation'
                  ) {
                    if (onSubmitSuccess) {
                      onSubmitSuccess(e)
                    }
                    getObservationDetails()
                    dispatch(
                      showSuccessAlert(
                        'Observation details updated successfully'
                      )
                    )
                  } else {
                    dispatch(
                      showErrorAlert('Error while updating observation details')
                    )
                  }
                })
              }}
              onEdit={(
                observation: R4.IObservation,
                onSubmitSuccess?: (data: any) => void
              ) => {
                const updatedObservation =
                  getObservationResourceForPostForSystemicExamForGe(
                    fhirAppointmentDetails.patient.id!,
                    fhirAppointmentDetails.encounter!.id!,
                    [],
                    observation,
                    undefined,
                    carePlan
                  )

                addObservationDetailsForSys(updatedObservation).then((e) => {
                  if (e === false) {
                    dispatch(
                      showErrorAlert('Error while updating observation details')
                    )
                  } else if (
                    (e as R4.IObservation).resourceType === 'Observation'
                  ) {
                    if (onSubmitSuccess) {
                      onSubmitSuccess(e)
                    }

                    dispatch(
                      showSuccessAlert(
                        'Observation details updated successfully'
                      )
                    )
                    getObservationDetails()
                  } else {
                    dispatch(
                      showErrorAlert('Error while updating observation details')
                    )
                  }
                })
              }}
              onDelete={(
                observation: R4.IObservation,
                onSuccess?: ((data: any) => void) | undefined,
                onError?: ((data: any) => void) | undefined
              ) => {
                deleteObservationDetails(observation).then((e) => {
                  if (e) {
                    dispatch(
                      showSuccessAlert(
                        'Observation details deleted successfully'
                      )
                    )
                    getObservationDetails()
                    if (onSuccess) onSuccess({})
                  } else if (onError) onError({})
                })
              }}
              formSection='soap'
              formName='Genitourinary Examination_Evaluation of QoL _Endometriosis and Adenomyosis_OCA'
              formCategory='assessment'
              displayAsPopup={false}
              isViewOnly={isReadOnly}
              showAdded={true}
              existingObservations={observations}
            />
          </Box>
        )}
    </Box>
  )
}
