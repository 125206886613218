/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  QuestionnaireStatusKind,
  Questionnaire_ItemTypeKind,
} from '@ahryman40k/ts-fhir-types/lib/R4'
import { Divider } from '@mantine/core'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  Checkbox,
} from '@material-ui/core'
import { MembershipDetails } from 'models/membershipDetails'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import {
  requestQuestionForDoctor,
  resetSearchQuestion,
} from 'redux/appointments/questionSearch/questuionSearchSlice'
import { RootState } from 'redux/rootReducer'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
} from 'services/userDetailsService'
import { getNameFromHumanName } from 'utils/fhirResourcesHelper'
import {
  addQuestion,
  updateQuestion,
} from 'utils/fhirResoureHelpers/appointmentHelpers'
import { ValueSetSelectMultipleWithSwitch } from 'views/components/common/multipleSelectValueFromValuesetwithSwitch'

// import EditTherapy from './editTherapy';

export interface MembershipSearchProps {
  onTap?: () => void
  onAdd?: () => void
}

export const PreAppointmentQuestions: React.FC<MembershipSearchProps> = ({
  onTap,
  onAdd,
}) => {
  const questionSearchSlice = useSelector(
    (state: RootState) => state.questionSearchSlice
  )

  const questionDataInitial: R4.IQuestionnaire = {
    resourceType: 'Questionnaire',
    version: '0.10',
    name: 'ProviderPreAppointmentQuestionnaire',
    title: 'Provider Pre-Appointment Questionnaire',
    status: QuestionnaireStatusKind._active,
    subjectType: ['Patient'],
    date: moment().format('YYYY-MM-DD'),
    publisher: getNameFromHumanName(
      getCurrentUserPractitionerDetails().name ?? []
    ),

    description: 'Patient discharged feedback',
    copyright: 'NA',
    approvalDate: moment().format('YYYY-MM-DD'),
    lastReviewDate: '2023-11-02',
    effectivePeriod: {
      start: moment().format('YYYY-MM-DD'),
      end: '2025-11-02',
    },
    code: [
      {
        system: 'http://loinc.org',
        code: '71950-0',
        display: 'Health Assessment Questionnaire [HAQ]',
      },
      {
        system: 'http://snomed.info/sct',
        code: '273510007',
        display: 'Health assessment questionnaire',
      },
    ],
    extension: [
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-practitioner-role-ext',
        valueReference: {
          reference: `PractitionerRole/${
            getCurrentUserPractitionerRoleDetails().id ?? ''
          }`,
        },
      },
    ],
    item: [
      {
        linkId: `1.1`,
        text: 'Reason for Visit',
        type: Questionnaire_ItemTypeKind._display,
        code: [
          {
            code: '406524005',
            system: 'http://snomed.info/sct',
            display: 'Reason for Visit',
          },
        ],
        required: true,
      },
    ],
  }

  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const [loading, setLoading] = useState<boolean>(false)

  const [selectedMembership, setselectedMembership] =
    useState<MembershipDetails>()
  const dispatch = useDispatch()

  const [showPopup, setShowPopup] = React.useState<boolean>(false)
  const [edit, setEdit] = React.useState<boolean>(false)

  const [codingData, setCodingData] = React.useState<R4.ICoding[]>([])
  const [loadingData, setLoadingData] = React.useState<boolean>(false)

  function updatePreAppointmentQuestion(
    question: R4.IQuestionnaire,
    codingArray: R4.ICoding[]
  ) {
    setUpdateStatus({ requesting: true })

    const oldProcedure: R4.IQuestionnaire = {
      ...question,
    }
    setLoading(true)
    const item: R4.IQuestionnaire_Item[] = []
    for (let i = 0; i < codingArray.length; i++) {
      const questionData = {
        linkId: `1.${i + 1}`,
        text: codingArray[i].display ?? '',
        type: Questionnaire_ItemTypeKind._display,
        code: [
          {
            code: codingArray[i].code,
            system: codingArray[i].system,
            display: codingArray[i].display ?? '',
          },
        ],
        required: true,
      }
      item.push(questionData)
    }
    oldProcedure.item = item
    updateQuestion(oldProcedure).then((e) => {
      setLoadingData(false)
      if (e) {
        setLoading(false)
        setShowPopup(false)
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        dispatch(showSuccessAlert('Questionnaire details updated successfully'))
        dispatch(resetSearchQuestion())
        dispatch(
          requestQuestionForDoctor(
            getCurrentUserPractitionerRoleDetails().id ?? ''
          )
        )
      } else {
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        setLoading(false)
        dispatch(
          showErrorAlert(
            'Error while updating Questionnaire details. Please try again later'
          )
        )
      }
    })
  }

  function AddPreAppointmentQuestion(
    question: R4.IQuestionnaire,
    codingArray: R4.ICoding[]
  ) {
    setUpdateStatus({ requesting: true })

    const oldProcedure: R4.IQuestionnaire = {
      ...question,
    }
    setLoading(true)
    const item: R4.IQuestionnaire_Item[] = []
    for (let i = 0; i < codingArray.length; i++) {
      const questionData = {
        linkId: `1.${i + 1}`,
        text: codingArray[i].display ?? '',
        type: Questionnaire_ItemTypeKind._display,
        code: [
          {
            code: codingArray[i].code,
            system: codingArray[i].system,
            display: codingArray[i].display ?? '',
          },
        ],
        required: true,
      }
      item.push(questionData)
    }
    oldProcedure.item = item
    addQuestion(oldProcedure).then((e) => {
      if (e) {
        setLoading(false)
        setShowPopup(false)
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        setLoadingData(false)
        dispatch(showSuccessAlert('Questionnaire details updated successfully'))
        dispatch(resetSearchQuestion())
        dispatch(
          requestQuestionForDoctor(
            getCurrentUserPractitionerRoleDetails().id ?? ''
          )
        )
      } else {
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        setLoading(false)
        dispatch(
          showErrorAlert(
            'Error while updating Questionnaire details. Please try again later'
          )
        )
      }
    })
  }

  useEffect(() => {
    dispatch(
      requestQuestionForDoctor(getCurrentUserPractitionerRoleDetails().id ?? '')
    )
  }, [dispatch])

  useEffect(() => {
    if (
      questionSearchSlice.resultsAvailable &&
      questionSearchSlice.question &&
      questionSearchSlice.prSelectedCoding
    )
      setLoadingData(true)
    setCodingData(questionSearchSlice.prSelectedCoding ?? [])
    setEdit(false)
  }, [questionSearchSlice])

  return (
    <div
      style={{
        overflow: 'auto',
        height: '100%',
        width: '100%',
      }}
    >
      <Paper
        elevation={0}
        style={{
          backgroundColor: 'transparent',
          overflow: 'auto',
          height: '100%',
          width: '100%',
        }}
      >
        <Box
          display='flex'
          flexGrow={1}
          flexDirection='column'
          width='100%'
          height='100%'
          justifyContent='center'
          alignContent='center'
          paddingTop={1}
          style={{ overflow: 'auto', maxHeight: '100%' }}
        >
          <Box
            display='flex'
            flexDirection='row'
            width='100%'
            justifyContent='space-between'
            alignContent='center'
            alignItems='center'
            overflow='hidden'
            padding={1}

            // flexGrow={1}
          >
            <Typography
              variant='h6'
              color='primary'
              style={{
                fontFamily: 'Open Sans',

                fontWeight: 'bold',
                lineHeight: 'normal',
                marginTop: 8,
                marginBottom: 8,
              }}
            >
              Pre Appointment Questionnaire
            </Typography>
          </Box>
          <Divider />
          {questionSearchSlice.searchingAppointments && (
            <Box
              display='flex'
              flexGrow={1}
              flexDirection='column'
              justifyContent='center'
              alignContent='center'
              overflow='auto'
              height='100%'
              width='100%'
            >
              <Box
                flexDirection='row'
                display='flex'
                alignSelf='center'
                justifyContent='center'
              >
                <CircularProgress size={25} />
              </Box>
            </Box>
          )}
          <Box
            display='flex'
            flexGrow={4}
            flexDirection='column'
            justifyContent='center'
            alignContent='center'
            overflow='auto'
            style={{
              backgroundColor: '#ececec',
            }}
          >
            {questionSearchSlice.noResultsAvailable && (
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                height='100%'
                style={{ backgroundColor: 'white' }}

                // flexGrow={1}
              >
                <ValueSetSelectMultipleWithSwitch
                  title='Pre-Appointment Questionnaire'
                  url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-pre-appointment-question-section-vs'
                  id='question'
                  fuzzySearch={true}
                  //   disabled={
                  //     (org.systemOfMedicine &&
                  //       org.systemOfMedicine.length === 0) ||
                  //     checkForAllopathy(org) === true
                  //   }
                  disabled={false}
                  onOptionSelected={(selectedKriya: R4.ICoding[]) => {
                    setCodingData(selectedKriya)
                  }}
                  preSelectedOptions={
                    questionSearchSlice.prSelectedCoding ?? []
                  }
                  helperText={undefined}
                  onLoading={(loaded: boolean) => {
                    setShowPopup(true)
                  }}
                />
                {codingData.length > 0 && loading === false && showPopup && (
                  <Box width='20%' paddingLeft={1.5}>
                    <Button
                      variant='contained'
                      color='primary'
                      tabIndex={0}
                      onClick={() => {
                        AddPreAppointmentQuestion(
                          questionDataInitial,
                          codingData
                        )
                      }}
                    >
                      Save
                    </Button>
                  </Box>
                )}
              </Box>
            )}
            {questionSearchSlice.errorWhileSearchingAppointments && (
              <Box
                //   display='flex'
                //   flexDirection='column'
                justifyContent='center'
                width='100%'
                height='100%'
                alignContent='center'
                paddingLeft={50}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container justifyContent='center'>
                      <Typography
                        variant='subtitle1'
                        color='error'
                        align='center'
                      >
                        Error while searching Pre Appointment Questionnaire.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}
            {questionSearchSlice.question &&
              questionSearchSlice.prSelectedCoding &&
              loadingData === true && (
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  height='100%'
                  style={{ backgroundColor: 'white' }}
                  tabIndex={0}

                  // flexGrow={1}
                >
                  <ValueSetSelectMultipleWithSwitch
                    title='Pre-Appointment Questionnaire'
                    url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-pre-appointment-question-section-vs'
                    id='question'
                    fuzzySearch={true}
                    preSelectedOptions={codingData}
                    disabled={false}
                    onOptionSelected={(selectedKriya: R4.ICoding[]) => {
                      setCodingData(selectedKriya)
                      setEdit(true)
                    }}
                    helperText={undefined}
                    //   error={
                    //     isUserDoctor(props.practitionerRole)
                    //       ? selectedConditionForCheckBox.length === 0
                    //       : undefined
                    //   }
                    onLoading={(loaded: boolean) => {
                      setShowPopup(true)
                    }}
                  />
                  {loadingData === true && loading === false && showPopup && (
                    <Box width='100%' display='flex' flexDirection='row'>
                      <Box paddingLeft={1.5}>
                        <Button
                          variant='contained'
                          color='primary'
                          tabIndex={0}
                          disabled={edit === false}
                          onClick={() => {
                            if (questionSearchSlice.question) {
                              updatePreAppointmentQuestion(
                                questionSearchSlice.question,
                                codingData
                              )
                            }
                          }}
                        >
                          Update
                        </Button>
                      </Box>
                      <Button
                        variant='contained'
                        color='primary'
                        disabled={edit === false}
                        tabIndex={0}
                        onClick={() => {
                          setCodingData([])
                          dispatch(
                            requestQuestionForDoctor(
                              getCurrentUserPractitionerRoleDetails().id ?? ''
                            )
                          )
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  )}
                </Box>
              )}
          </Box>
        </Box>
      </Paper>
    </div>
  )
}
